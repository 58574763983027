/**
 * Global authentication middleware for Nuxt routes.
 * This middleware checks if the route is public or private and handles authentication accordingly.
 * If the route is public, it allows navigation to the route.
 * If the route is private, it redirects the user to the login page if not authenticated.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const { context } = storeToRefs(usePortalStore())
  const sessionStore = useSessionStore()
  const { session } = storeToRefs(sessionStore)
  const returnToPath: string | null = !!to.query.returnTo && typeof to.query.returnTo === 'string' && to.query.returnTo.startsWith('/') && !to.query.returnTo.includes('/login') && !to.query.returnTo.includes('%2Flogin') ? to.query.returnTo : null
  const samlAuthenticationProcessing: boolean = to.query.loginSuccess === 'true' && String(to.query.method || '').toLowerCase() === 'saml' && !to.query.refreshed

  // Check if navigating to the /login route and the route contains a `returnTo` query parameter
  if (to.path.includes('/login') && !!returnToPath) {
    sessionStore.setLoginReturnPath(returnToPath)
  }

  /** Redirect the user to the stored return to path, or the homepage */
  const navigateToReturnToPathOrHomepage = () => {
    // Check if we have a stored login path locally before we clear the cookie
    const userReturnToPath = returnToPath || sessionStore.getLoginReturnPath() || '/'
    // If not navigating to the /login route, clear the stored return path cookie
    if (!to.path.includes('/login') && !samlAuthenticationProcessing) {
      sessionStore.setLoginReturnPath(null)
    }
    if (to.path !== userReturnToPath && !samlAuthenticationProcessing) {
      // Redirect the user to the path they were trying to access before logging in, or the homepage
      return navigateTo(userReturnToPath, {
        replace: true,
      })
    }
  }

  // If Portal authentication is not enabled
  if (context.value?.authentication_enabled !== true) {
    // Redirect the user to the stored return to path, or the homepage, if they are attempting to visit any unneeded paths when a Portal is public.
    // Important: never include `/logout` in this list.
    const redirectPublicPortalPaths = ['/login', '/login/sso', '/register', '/forgot-password', '/reset-password']
    if (redirectPublicPortalPaths.includes(to.path) && to.path !== '/') {
      return navigateToReturnToPathOrHomepage()
    }

    // Allow navigation to the route since portal authentication is not enabled
    return
  }

  // If portal authentication is enabled
  if (context.value?.authentication_enabled === true) {
    /**
     * If attempting to navigate to a Custom Page,
     * or any of the `/apis/**` routes
     * allow navigation and the `PageMdcComponent.vue` component
     * will handle the authentication logic based on if the page is public or private.
     *
     * !Important: Any page that can render a Custom MDC Page should be allowed here.
     * !Important: Any page within the `/apis/{api_slug}` route should be allowed here since the pages can be public.
     */
    if (to.name === 'page_slug' || to.path.startsWith('/apis/')) {
      return
    }

    /**
     * If preview mode is enabled, and the user is attempting to navigate to a Snippet preview,
     * allow navigation and the `[snippet_name].vue` Page component will handle the authentication logic.
     */
    if (to.name === '_preview-mode-snippets-snippet_name') {
      return
    }

    // If the user is not authenticated and attempting to visit a page other than one of the allowed paths
    const unauthenticatedAllowedPaths = [
      // Pages
      '/login', '/login/sso', '/logout', '/register', '/forgot-password', '/reset-password',
      // Sitemap/Robots
      '/robots.txt', '/sitemap.xml', '/sitemap_index.xml', '/__sitemap__/', '/__og-image__/',
      // API Paths
      '/api/', '/_api/', '/_proxy/',
    ]

    if (!session.value.authenticated && !unauthenticatedAllowedPaths.some(path => to.path === path || to.path.startsWith(`${path}/`))) {
      // Store the current route in the cookie to redirect on login
      sessionStore.setLoginReturnPath(to.fullPath)

      // Redirect the user to the /login page
      return navigateTo({
        path: '/login',
      })
    }

    // If the user is already authenticated and attempting to visit a path that should not be
    // accessible when authenticated, redirect to the stored return to path, or the homepage
    const authenticatedDisallowedPaths = ['/login', '/register', '/forgot-password', '/reset-password']
    if (session.value.authenticated && authenticatedDisallowedPaths.includes(to.path)) {
      return navigateToReturnToPathOrHomepage()
    }
  }
})
