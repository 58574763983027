/** The global search key for useState */
export const PORTAL_SEARCH_QUERY_KEY = 'portal-global-search-query'
/** The ID of the global search modal (`SearchModal.client.vue`) */
export const PORTAL_SEARCH_MODAL_ID = 'portal-global-search-modal'
/** The id of the div placed at the bottom of the search results to trigger fetching the next page */
export const PORTAL_SEARCH_INFINITE_SCROLL_ELEMENT_ID = 'portal-global-search-results-end'

/**
 * The reusable, shared prop interface for `src/components/search-results/entity-types/*.vue` components. This can be extended if needed, although not ideal.
 * @example const props = defineProps(SEARCH_RESULT_PROPS)
 */
export const SEARCH_RESULT_PROPS = {
  result: {
    type: Object as PropType<PortalApiResponseTemp<'search-portal'>['data'][0]>,
    required: true,
    default: () => {},
    validator: (result: PortalApiResponseTemp<'search-portal'>['data'][0]): boolean => {
      if (!result || !result?.type || !result?.id) {
        return false
      }

      return true
    },
  },
}
