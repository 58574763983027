export const useLayoutStore = defineStore('layout', () => {
  /** Is the mobile menu visible */
  const mobileMainMenuIsVisible = ref<boolean>(false)
  /** On mobile, is the left sidebar visible */
  const mobileSidebarLeftIsVisible = ref<boolean>(false)
  /** On mobile, is the right sidebar visible */
  const mobileSidebarRightIsVisible = ref<boolean>(false)
  /** Is the search modal visible */
  const searchModalIsVisible = ref<boolean>(false)

  /** Close all sidebars on mobile */
  const closeAllSidebars = (): void => {
    mobileMainMenuIsVisible.value = false
    mobileSidebarLeftIsVisible.value = false
    mobileSidebarRightIsVisible.value = false
  }

  /** If true, show the .global-page-overlay */
  const showPageOverlay = computed((): boolean => {
    // Note: We do not show the overlay for mobileMainMenuIsVisible
    return mobileSidebarLeftIsVisible.value ||
    mobileSidebarRightIsVisible.value
  })

  // Hide other sidebars when the mobileMainMenuIsVisible is true
  watch(mobileMainMenuIsVisible, (isVisible: boolean) => {
    if (isVisible) {
      mobileSidebarLeftIsVisible.value = false
      mobileSidebarRightIsVisible.value = false
    }
  })

  // Hide other sidebars when the mobileSidebarLeftIsVisible is true
  watch(mobileSidebarLeftIsVisible, (isVisible: boolean) => {
    if (isVisible) {
      mobileMainMenuIsVisible.value = false
      mobileSidebarRightIsVisible.value = false
    }
  })

  // Hide other sidebars when the mobileSidebarRightIsVisible is true
  watch(mobileSidebarRightIsVisible, (isVisible: boolean) => {
    if (isVisible) {
      mobileMainMenuIsVisible.value = false
      mobileSidebarLeftIsVisible.value = false
    }
  })

  watch(searchModalIsVisible, (visible: boolean) => {
    if (import.meta.client && !visible) {
      // Ensure the body is scrollable when the search modal is closed
      document?.body?.classList?.remove('k-modal-overflow-hidden')
    }
  })

  const router = useRouter()
  router.afterEach(() => {
    // Close all sidebars when navigating to a new route
    closeAllSidebars()
  })

  useEventListener(window, 'resize', () => {
    closeAllSidebars()
  })

  return {
    mobileMainMenuIsVisible,
    mobileSidebarLeftIsVisible,
    mobileSidebarRightIsVisible,
    searchModalIsVisible,
    showPageOverlay,
    // Methods
    closeAllSidebars,
  }
})
