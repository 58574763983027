<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="search-modal-footer">
    <div class="hint">
      <span class="hint-term">{{ t('search.hints.operators') }}:</span>
      <span class="hint-value">NOT, AND, OR</span>
      <span class="hint-separator" />

      <span class="hint-term">{{ t('search.hints.wildcard') }}:</span>
      <span class="hint-value">dev_*</span>
      <span class="hint-separator" />

      <span class="hint-term">{{ t('search.hints.exact') }}:</span>
      <span class="hint-value">"foo bar"</span>
      <span class="hint-separator" />

      <!-- <span class="hint-term">{{ t('search.hints.by_type') }}:</span>
      <span class="hint-value">type:{entity_type}</span><br> -->

      <!-- <span class="hint-term">{{ t('search.hints.by_labels') }}:</span>
      <span class="hint-value">labels.{key}:{value}</span>
      <span class="hint-separator" /> -->

      <!-- <span class="hint-term">{{ t('search.hints.by_attribute') }}:</span>
      <span class="hint-value">@{attribute_name}:{value}</span> -->
    </div>
    <!-- TODO: activate it once we have a link -->
    <!-- <div class="hint-link">
      <KExternalLink
        hide-icon
        href="https://docs.konghq.com"
      >
        <BookIcon
          decorative
          :size="KUI_ICON_SIZE_40"
        />
        &nbsp;{{ t('labels.search_help') }}
      </KExternalLink>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import { BookIcon } from '@kong/icons'
import { KUI_ICON_SIZE_40 } from '@kong/design-tokens'

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.search-modal-footer {
  align-items: center;
  border-top: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
  box-sizing: border-box;
  display: flex;
  font-size: var(--kui-font-size-30, $kui-font-size-30);
  justify-content: space-between;
  padding: var(--kui-space-60, $kui-space-60) var(--kui-space-80, $kui-space-80);
  width: 100%;
}

.hint-link,
.hint-term,
.hint-value {
  white-space: nowrap;
}

.hint-link {
  display: inline-block !important;
  margin-left: auto;
  vertical-align: middle !important;

  :deep(.kui-icon) {
    position: relative;
    top: 1px;
  }
}

.hint {
  color: var(--kui-color-text, $kui-color-text);
  font-family: var(--kui-font-family-code, $kui-font-family-code);
  font-size: 11px;
  padding-right: var(--kui-space-20, $kui-space-20);

  @media (max-width: ($kui-breakpoint-mobile - 1px)) {
    display: none;
  }

  .hint-term {
    font-weight: var(--kui-font-weight-medium, $kui-font-weight-medium);
    padding-right: var(--kui-space-20, $kui-space-20);
  }

  .hint-value {
    color: var(--kui-color-text-neutral, $kui-color-text-neutral);
    font-family: var(--kui-font-family-code, $kui-font-family-code);
    letter-spacing: var(--kui-letter-spacing-minus-40, $kui-letter-spacing-minus-40);
  }

  .hint-separator {
    padding-left: var(--kui-space-20, $kui-space-20);
    padding-right: var(--kui-space-20, $kui-space-20);

    &:after {
      content: "•";
    }
  }
}
</style>
