import { COOKIE_PREVIEW_MODE, COOKIE_PREVIEW_ID } from '../constants/preview-mode'
import { withLeadingSlash } from 'ufo'

let unregisterRefreshHook: (() => any) | undefined
const previewKey = 'portal-preview-mode'

export default function usePortalPreviewMode() {
  const enabled = useState<boolean>(`${previewKey}-enabled`, () => false)
  const state = useState<PreviewState>(`${previewKey}-state`, () => ({
    data: {},
  }))

  // Init preview cookies
  const previewCookie = useCookie(COOKIE_PREVIEW_MODE)
  const previewIdCookie = useCookie(COOKIE_PREVIEW_ID)

  if (!enabled.value) {
    enabled.value = !!previewCookie.value && String(previewCookie.value) === 'true' && !!previewIdCookie.value && isValidUUID(previewIdCookie.value)
  }

  watch(enabled, async (isEnabled) => {
    if (isEnabled) {
      const newState: PreviewState = {
        preview_id: previewIdCookie.value || undefined,
        data: state.value.data,
      }

      // Update the preview state if it differs from the current state
      if (JSON.stringify(newState) !== JSON.stringify(state.value)) {
        state.value = Object.assign(state.value, newState)
      }

      // Register a hook to refresh Nuxt data when navigating after the preview mode is disabled
      if (import.meta.client && !unregisterRefreshHook) {
        unregisterRefreshHook = async () => {
          // Clear the state data
          state.value.preview_id = undefined
          state.value.data = {}

          // Clear the preview mode cookies
          previewCookie.value = null
          previewIdCookie.value = null

          // Clear the preview mode query params if they exist
          if (useRoute().query.preview || useRoute().query.preview_id) {
            await useRouter().replace({
              query: {
                // Keep other query params
                ...useRoute().query,
                // Remove preview mode query params
                ...{ preview: undefined, preview_id: undefined, ref: undefined },
              },
            })
          }

          if (previewKey) {
            clearNuxtData(`${previewKey}-enabled`)
            clearNuxtData(`${previewKey}-state`)
          }
          reloadNuxtApp({
            persistState: false,
            // Append the `preview=false` query param to the current route path to force-disable preview mode
            // Append a timestamp ref query param to the current route path for cache-busting
            path: `${useRoute().path}?preview=false&ref=${Date.now().toString()}`,
          })
        }
      }
    } else if (isEnabled === false && unregisterRefreshHook) {
      // Call the hook
      await unregisterRefreshHook()
      // Clear the hook
      unregisterRefreshHook = undefined
    }
  }, { immediate: true, flush: 'sync' })

  /**
   * Adds or updates a new page to the portal preview mode.
   *
   * @param {string} path - The path of the page to be added.
   * @param {string} content - The raw markdown content of the page to be added.
   * @returns void
   */
  const updatePage = (path: string, content: string): void => {
    // Store the new content
    state.value.data.pages = state.value.data.pages || new Map()

    // Ensure the path has a leading slash
    const pagePath = withLeadingSlash(path)
    const existingPage = state.value.data.pages.get(pagePath)
    const newCacheKey = existingPage ? (existingPage.cacheKey || 0) + 1 : 0

    // Create or update the page
    state.value.data.pages.set(pagePath, {
      cacheKey: newCacheKey,
      // If content is empty, pass an invisible character to ensure the empty page is rendered
      content: content || '\u200B',
    })
  }

  /**
   * Adds or updates a new snippet to the portal preview mode.
   *
   * @param {string} name - The name of the snippet to be added.
   * @param {string} content - The raw markdown content of the snippet to be added.
   * @returns void
   */
  const updateSnippet = (name: string, content: string): void => {
    // Store the new content
    state.value.data.snippets = state.value.data.snippets || new Map()

    const snippetName = String(name || '').trim()
    const existingSnippet = state.value.data.snippets.get(snippetName)
    const newCacheKey = existingSnippet ? (existingSnippet.cacheKey || 0) + 1 : 0

    // Create or update the snippet
    state.value.data.snippets.set(snippetName, {
      cacheKey: newCacheKey,
      // If content is empty, pass an invisible character to ensure the empty snippet is rendered
      content: content || '\u200B',
    })
  }

  /**
   * Adds or updates the global CSS to the portal preview mode.
   *
   * @param {PortalCustomization['theme']} themeConfig - The theme configuration object.
   * @returns void
   */
  const updateTheme = (themeConfig?: PortalApiResponseTemp<'get-portal-customization'>['theme']): void => {
    if (!themeConfig || typeof themeConfig !== 'object') {
      /**
       * !Important: if the content is empty, we need to reset the CSS to an
       * empty selector in order to clear any previously injected preview CSS.
       */
      state.value.data.theme = undefined
      return
    }

    // Store the new theme config
    state.value.data.theme = themeConfig
  }

  /**
   * Adds or updates the global CSS to the portal preview mode.
   *
   * @param {string} content - The raw markdown content of the snippet to be added.
   * @returns void
   */
  const updateGlobalCss = (content: string): void => {
    if (!content || typeof content !== 'string') {
      /**
       * !Important: if the content is empty, we need to reset the CSS to an
       * empty selector in order to clear any previously injected preview CSS.
       */
      state.value.data.css = ':root{}'
      return
    }

    // Store the new CSS content
    state.value.data.css = content.trim()
  }

  const exit = (): void => {
    // Clear the preview mode cookies and exit
    enabled.value = false

    if (import.meta.client) {
      // If in an iframe, always postMessage to the parent window that the app is exiting preview mode
      // !Important: we do this outside the watcher purposefully to ensure the message is always sent even if preview mode is disabled
      if (isPortalInIframe()) {
        window.parent.postMessage({ action: 'portal:preview:exit' }, '*')
      }

      // If window.opener exists, always postMessage to the parent window that the app is exiting preview mode
      // !Important: we do this outside the watcher purposefully to ensure the message is always sent even if preview mode is disabled
      if (window.opener) {
        window.opener.postMessage({ action: 'portal:preview:exit' }, '*')
      }
    }
  }

  return {
    enabled,
    state,
    // methods
    updatePage,
    updateSnippet,
    updateTheme,
    updateGlobalCss,
    exit,
  }
}
