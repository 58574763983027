<template>
  <SearchResultBase
    data-testid="fallback-result"
    :disabled="true"
  >
    <template
      v-if="result.description"
      #default
    >
      <p data-testid="description">
        {{ result.description }}
      </p>
    </template>
  </SearchResultBase>
</template>

<script setup lang="ts">
const props = defineProps(SEARCH_RESULT_PROPS)
const { provideSearchResult } = useSearch()

/**
 * !Important: This is a fallback component, should a search result not match any of the entity type components.
 * A URL can not be determined since the `props.result.type` of this search result is unknown.
 */
console.warn(`An unknown KSearch result type '${props.result.type}' has been returned by the API; showing the fallback component.`, props.result)

// Provide the search result details to the child BaseResult component
provideSearchResult({
  ...props.result,
  // Since this is a fallback result component, we cannot provide a URL to the entity in Konnect
  url: '', // Leave empty
})
</script>
