/**
 * Retrieves the menu items for the specified menu location.
 *
 * @template T - The type of items to be returned.
 * @param {PortalMenuLocation} menuLocation - The location of the menu to retrieve.
 * @returns {ComputedRef<T[]>} A computed array of menu items for the specified menu location.
 * @example Example usage:
 * const mainMenuItems = useNavMenu<PortalMenuItem>('main')
 * const footerSectionMenuItems = useNavMenu<PortalFooterSection>('footer_sections')
 * const footerBottomMenuItems = useNavMenu<PortalMenuItem>('footer_bottom')
 */
export default function useNavMenu<T extends PortalMenuItem | PortalFooterSection>(menuLocation: PortalMenuLocation): ComputedRef<T[]> {
  const { context, customization } = storeToRefs(usePortalStore())
  const { session } = storeToRefs(useSessionStore())

  /**
   * Checks if the given item is a PortalMenuItem.
   * @param item - The item to check.
   * @returns True if the item is a PortalMenuItem, false otherwise.
   */
  const isPortalMenuItem = (item: any): item is PortalMenuItem => typeof item.visibility === 'string' && (item.visibility === 'public' || item.visibility === 'private')

  /**
   * Checks if the given item is a PortalFooterSection.
   * @param item - The item to be checked.
   * @returns True if the item is a PortalFooterSection, false otherwise.
   */
  const isPortalFooterMenuSection = (item: any): item is PortalFooterSection => Array.isArray(item.items)

  /**
   * Filters an array of menu items based on their public visibility and the user's authentication status.
   *
   * @template ItemType - The type of items in the array.
   * @param {ItemType[]} items - The array of items to filter.
   * @returns {ItemType[]} - The filtered array of items.
   */
  const filterPublicItems = <ItemType extends PortalMenuItem | PortalFooterSection>(items: ItemType[]): ItemType[] =>{
    return items.reduce((acc: ItemType[], item) => {
      if (isPortalMenuItem(item)) {
        if (context.value?.authentication_enabled === false || item.visibility === 'public' || session.value.authenticated === true) {
          acc.push(item)
        }
      } else if (isPortalFooterMenuSection(item)) {
        const publicItems = item.items.filter(subItem => (context.value?.authentication_enabled === false || subItem.visibility === 'public' || session.value.authenticated === true))
        if (publicItems.length > 0) {
          acc.push({ ...item, items: publicItems })
        }
      }
      return acc
    }, [])
  }

  return computed((): T[] => {
    if (menuLocation === 'footer_sections') {
      return filterPublicItems(customization.value?.menu?.[menuLocation] || []) as T[]
    }

    return filterPublicItems(customization.value?.menu?.[menuLocation] || []) as T[]
  })
}
