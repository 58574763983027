<template>
  <div
    v-if="context?.authentication_enabled === true"
    class="header-account"
  >
    <div
      v-if="!session.authenticated"
      class="account-actions"
    >
      <PortalKButton
        v-if="route.name !== 'login-login_path'"
        appearance="tertiary"
        data-testid="header-log-in-button"
        :to="{ name: 'login-login_path'}"
      >
        {{ t('actions.log_in') }}
      </PortalKButton>
      <PortalKButton
        v-if="route.name !== 'register'"
        appearance="primary"
        class="sign-up-button"
        :class="{ visible: route.name === 'login-login_path' }"
        data-testid="header-sign-up-button"
        :to="{ name: 'register'}"
      >
        {{ t('actions.sign_up') }}
      </PortalKButton>
    </div>
    <KDropdown
      v-else
      data-testid="account-dropdown"
      :items="dropdownItems"
      :kpop-attributes="{ placement: 'bottom-end' }"
      show-caret
    >
      <PortalKButton
        appearance="tertiary"
        :aria-label="t('labels.toggle_account_dropdown')"
        class="account-dropdown-trigger"
        data-testid="account-dropdown-trigger"
        icon
        size="large"
        type="button"
      >
        <ProfileIcon
          decorative
          :size="KUI_ICON_SIZE_50"
        />
      </PortalKButton>
      <template #items>
        <KDropdownItem
          v-if="session.developer?.full_name || session.developer?.email"
          class="account-dropdown-developer-context"
          data-testid="account-dropdown-developer-context"
        >
          <div
            class="developer-context"
            @click.stop
          >
            <div
              v-if="session.developer?.full_name"
              class="developer-name"
              data-dd-privacy="mask"
              data-testid="developer-name"
            >
              {{ session.developer.full_name }}
            </div>
            <div
              v-if="session.developer?.email"
              class="developer-email"
              data-dd-privacy="mask"
              data-testid="developer-email"
            >
              {{ session.developer.email }}
            </div>
          </div>
        </KDropdownItem>
        <KDropdownItem
          v-for="option in dropdownItems"
          :key="option.label"
          :class="['account-dropdown-item', { 'has-divider': option.hasDivider }]"
          :danger="option.danger"
          :has-divider="option.hasDivider"
          @click.prevent="goToUrl(option.to)"
        >
          {{ option.label }}
        </KDropdownItem>
      </template>
    </KDropdown>
  </div>
</template>

<script setup lang="ts">
import { ProfileIcon } from '@kong/icons'
import type { DropdownItem } from '@kong/kongponents'
import { KUI_ICON_SIZE_50 } from '@kong/design-tokens'
import type { RouteLocationRaw } from '#vue-router'

const { context } = storeToRefs(usePortalStore())
const { session } = storeToRefs(useSessionStore())
const { t } = useI18n()
const route = useRoute()

const dropdownItems = computed(():DropdownItem[] => {
  return [
    {
      label: t('pages.apps.title'),
      to: { path: '/apps' },
      hasDivider: true, // The first item should have a divider
    },
    {
      label: t('actions.log_out'),
      to: { path: '/logout' },
      hasDivider: true, // Log out action should have a divider
    },
  ]
})

// Navigate to the provided path
const goToUrl = async (path: RouteLocationRaw | undefined | null): Promise<void> => {
  await navigateTo(path)
}
</script>

<style lang="scss" scoped>
.account-actions {
  display: none;
  gap: var(--kui-space-20, $kui-space-20);

  @media (min-width: $kui-breakpoint-tablet) {
    display: flex;
    gap: var(--kui-space-40, $kui-space-40);
  }
}

.sign-up-button {
  @media (max-width: ($kui-breakpoint-mobile - 1px)) {
    &:not(.visible) {
      display: none;
    }
  }
}

.account-dropdown-trigger {
  align-items: center;
  border: none;
  border-radius: var(--kui-border-radius-round, $kui-border-radius-round);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  justify-self: flex-end;
  padding: var(--kui-space-0, $kui-space-0) !important;
}

.account-dropdown-developer-context {
  user-select: none;

  :deep(.dropdown-item-trigger) {
    &:hover {
      background-color: var(--kui-color-background, $kui-color-background) !important;
      cursor: auto !important;
    }
  }

  .developer-context {
    max-width: 100%;
  }

  .developer-name {
    font-size: var(--kui-font-size-30, $kui-font-size-30);
    line-height: var(--kui-line-height-30, $kui-line-height-30);
  }

  .developer-email {
    color: var(--kui-color-text-neutral, $kui-color-text-neutral);
    font-size: var(--kui-font-size-20, $kui-font-size-20);
    line-height: var(--kui-line-height-20, $kui-line-height-20);
  }

  // Truncate the strings if too long
  .developer-name,
  .developer-email {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
