import { defineNuxtPlugin } from '#app/nuxt'
import { LazyApisList, LazyPageSnippet, LazyCopyButton, LazyEmptyState, LazyGridItem, LazyTruncateContent, LazyAccordionGroup, LazyAccordionPanel, LazyMultiColumn, LazyPageContainer, LazyPageHero, LazyPageSection, LazyPageToc, LazyPageTocItem, LazyPortalCard, LazyPortalIcon, LazyPortalKAlert, LazyPortalKBadge, LazyPortalKButton, LazyPortalKTooltip, LazyProseIframe, LazyProseImg, LazyProsePre, LazyProseTable, LazyTabGroup, LazyTabPanel, LazyProseA, LazyProseBlockquote, LazyProseCode, LazyProseEm, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseLi, LazyProseOl, LazyProseP, LazyProseScript, LazyProseStrong, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["ApisList", LazyApisList],
["PageSnippet", LazyPageSnippet],
["CopyButton", LazyCopyButton],
["EmptyState", LazyEmptyState],
["GridItem", LazyGridItem],
["TruncateContent", LazyTruncateContent],
["AccordionGroup", LazyAccordionGroup],
["AccordionPanel", LazyAccordionPanel],
["MultiColumn", LazyMultiColumn],
["PageContainer", LazyPageContainer],
["PageHero", LazyPageHero],
["PageSection", LazyPageSection],
["PageToc", LazyPageToc],
["PageTocItem", LazyPageTocItem],
["PortalCard", LazyPortalCard],
["PortalIcon", LazyPortalIcon],
["PortalKAlert", LazyPortalKAlert],
["PortalKBadge", LazyPortalKBadge],
["PortalKButton", LazyPortalKButton],
["PortalKTooltip", LazyPortalKTooltip],
["ProseIframe", LazyProseIframe],
["ProseImg", LazyProseImg],
["ProsePre", LazyProsePre],
["ProseTable", LazyProseTable],
["TabGroup", LazyTabGroup],
["TabPanel", LazyTabPanel],
["ProseA", LazyProseA],
["ProseBlockquote", LazyProseBlockquote],
["ProseCode", LazyProseCode],
["ProseEm", LazyProseEm],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
