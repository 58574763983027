// Import all of the `@kong/design-tokens` CSS Custom Properties as a inline (raw) string to make them available within custom pages and the rest of the app
import kongDesignTokensCssCustomProperties from '@kong/design-tokens/tokens/css/custom-properties.css?inline'

/**
 * Customize the Portal theme and global CSS based on the response from the `/customization` endpoint.
 */
export default defineNuxtPlugin({
  name: 'customization',
  dependsOn: ['datadog', 'fetch-portal-data'],
  parallel: true,
  async setup() {
    const { customization } = storeToRefs(usePortalStore())
    const { enabled: previewModeEnabled, state: previewModeState } = usePortalPreviewMode()
    const themeTokensLight = useState('theme-tokens-light-css', () => '')
    const themeTokensDark = useState('theme-tokens-dark-css', () => '')
    const requestURL = useRequestURL()
    const requestFetch = useRequestFetch()

    const fetchThemeTokens = async (brand?: string): Promise<{ light: string, dark: string }> => {
      try {
        const sharedRequestHeaders: HeadersInit = {
          accept: 'text/plain',
          // Add custom Portal Client headers for dynamic request routing
          'x-portal-context-origin': requestURL.origin,
          'x-portal-context-hostname': requestURL.hostname,
        }

        // Use requestFetch here to ensure we send along the original request headers (for deploy preview URLs)
        const [light, dark] = await Promise.all([
          // Fetch light mode tokens
          requestFetch('/api/theme/generate/tokens/css', {
            query: { mode: 'light', brand },
            headers: sharedRequestHeaders,
          }),
          // Fetch dark mode tokens
          requestFetch('/api/theme/generate/tokens/css', {
            query: { mode: 'dark', brand },
            headers: sharedRequestHeaders,
          }),
        ])

        return {
          light,
          dark,
        }
      } catch (error: any) {
        console.error('customization: Failed to fetch theme tokens CSS.', error)
        return {
          light: '',
          dark: '',
        }
      }
    }

    // Fetch the initial theme tokens CSS from the `/api/theme/generate/tokens/css` endpoint
    // !Important: do not depend on the watcher so that the styles are immediately available.
    const { light, dark } = await fetchThemeTokens(customization.value?.theme?.colors?.primary)
    themeTokensLight.value = light
    themeTokensDark.value = dark

    watch([previewModeEnabled, () => previewModeState.value.data.theme], async ([previewEnabled, themeConfig]) => {
      if (previewEnabled && (themeConfig?.mode || themeConfig?.colors?.primary)) {
        // Fetch the preview mode tokens
        const { light, dark } = await fetchThemeTokens(themeConfig?.colors?.primary)
        themeTokensLight.value = light
        themeTokensDark.value = dark
      } else {
        // Fetch the saved tokens
        const { light, dark } = await fetchThemeTokens(customization.value?.theme?.colors?.primary)
        themeTokensLight.value = light
        themeTokensDark.value = dark
      }
    }, { deep: true })

    /**
     * Set the themeConfigCss variable based on the the `customization.theme` property from the customization object or
     * to the preview mode `state.data.theme` if set.
     */
    const themeConfigCss = computed((): string => {
      if (!themeTokensLight.value || !themeTokensDark.value) {
        return ''
      }

      // For the light tokens, we also add a rule for the `:root` selector without color mode to serve as the fallback/default
      const light = parseCustomCss(wrapString(themeTokensLight.value, ':root, :root[data-portal-color-mode="light"] {', '}'))
      const dark = parseCustomCss(wrapString(themeTokensDark.value, ':root[data-portal-color-mode="dark"] {', '}'))

      return `${light} ${dark}`
    })

    /**
     * Set the customGlobalCss variable to the css property from the customization object or
     * to the preview mode `state.data.css` if set.
     */
    const customGlobalCss = computed((): string => {
      if (previewModeEnabled.value && typeof previewModeState.value.data.css === 'string') {
        return parseCustomCss(previewModeState.value.data.css)
      }
      return parseCustomCss(customization.value?.css || '')
    })

    // Tag priority is used to determine the order in which the tags are added to the head element.
    useHead({
      style: [
        /**
         * Load the `@kong/design-tokens` CSS Custom Properties to make them available within
         * the rest of the app.
         *
         * !Important: the base CSS custom property values **must** be loaded before the
         * customization and global CSS is applied.
         */
        {
          key: 'kong-design-tokens-css-custom-properties',
          'data-testid': 'kong-design-tokens-css-custom-properties',
          innerHTML: parseCustomCss(kongDesignTokensCssCustomProperties),
          tagPosition: 'head',
          tagPriority: 0,
        },
        // Theme CSS provided by the `/portal/customization` endpoint; injected to the top of the body element to ensure it's applied last.
        themeConfigCss.value.length ? {
          key: 'portal-theme-css',
          'data-testid': 'portal-theme-css',
          innerHTML: () => themeConfigCss.value,
          tagPosition: 'head',
          tagPriority: 900, // Ensure theme config styles are applied before `customGlobalCss` and `useCustomStyles` composable
        } : {},
        // Custom CSS provided by the `/portal/customization` endpoint; injected to the top of the body element to ensure it's applied last.
        customGlobalCss.value.length ? {
          key: 'portal-custom-css',
          'data-testid': 'portal-custom-css',
          innerHTML: () => customGlobalCss.value,
          tagPosition: 'head',
          tagPriority: 1000, // Ensure custom styles are applied last, but before `useCustomStyles` composable
        } : {},
      ],
    })
  },
})
