export const usePortalStore = defineStore('portal', () => {
  /** The Portal context data from /api/v[\d+]/portal */
  const context = ref<PortalApiResponseTemp<'get-portal-context'> & { feature_set: string }>()
  /** The exposed Konnect Portal config from /api/v[\d+]/customization */
  const customization = ref<PortalApiResponseTemp<'get-portal-customization'>>()
  // Get the cookie headers
  const cookieHeaders = useRequestHeaders(['cookie'])
  // Import session data
  const sessionStore = useSessionStore()
  const { session } = storeToRefs(sessionStore)
  const { portalAccessToken, portalRefreshToken } = useRuntimeConfig().public.cookies
  const cookieAccess = useCookie(portalAccessToken)
  const cookieRefresh = useCookie(portalRefreshToken)
  const requestURL = useRequestURL()
  const requestFetch = useRequestFetch()
  const { $datadogRum } = useNuxtApp()

  /**
   * Fetches the portal info
   */
  const fetchPortalContext = async (): Promise<void> => {
    try {
      context.value = await requestFetch('/api/v3/portal', {
        baseURL: '/_proxy', // Always proxy requests to the Portal API
        headers: {
          accept: 'application/json',
          ...(session.value.authenticated
            ? cookieHeaders
            : {
              // !Important: lowercase
              cookie: '',
            }),
          // Add custom Portal Client headers for dynamic request routing
          'x-portal-context-origin': requestURL.origin,
          'x-portal-context-hostname': requestURL.hostname,
        },
      })

      // If the Portal is public (or public status cannot be determined), clear the portalAccessToken and portalRefreshToken
      if (context.value?.authentication_enabled !== true) {
        cookieAccess.value = null
        cookieRefresh.value = null
        session.value.authenticated = false
      }

      if (context.value?.authentication_enabled === undefined) {
        // is_public property is undefined, show global error for now
        throw createError({
          // TODO: expose trace id in the UI
          statusCode: 500,
          statusMessage: $t('errors.portal_config.public_or_private'),
          fatal: true,
        })
      }

      // Set the portal's properties for DataDog
      $datadogRum.setUserProperty('portal_id', context.value.portal_id)
      $datadogRum.setUserProperty('org_id', context.value.org_id)
    } catch (error: any) {
      console.log('fetchPortalContext error', error)

      // Clear the auth cookies if 401
      if (error.statusCode === 401 || error.status === 401) {
        cookieAccess.value = null
        cookieRefresh.value = null
        session.value.authenticated = false
      }

      throw createError({
        // TODO: expose trace id in the UI
        statusCode: error.status || error.response?.status || 500,
        statusMessage: error.statusMessage || $t('errors.portal_config.info'),
        fatal: true,
      })
    }
  }

  /**
   * Fetches the portal customization
   */
  const fetchPortalCustomization = async (): Promise<void> => {
    try {
      customization.value = await requestFetch('/api/v3/customization', {
        baseURL: '/_proxy', // Always proxy requests to the Portal API
        headers: {
          accept: 'application/json',
          ...(session.value.authenticated
            ? cookieHeaders
            : {
              // !Important: lowercase
              cookie: '',
            }),
          // Add custom Portal Client headers for dynamic request routing
          'x-portal-context-origin': requestURL.origin,
          'x-portal-context-hostname': requestURL.hostname,
        },
      })
    } catch (error: any) {
      console.log('customization error', error)
    }
  }

  /** Fetch/refresh all core Portal data */
  const fetchAllPortalData = async (): Promise<void> => {
    try {
      // Fetch portal info first to determine public/private portal
      await fetchPortalContext()

      // Then fetch remaining config endpoints
      await Promise.all([
        fetchPortalCustomization(),
      ])
    } catch {
      // no-op
    }
  }

  /** Refresh all Portal config data that is dynamic based on the user's authenticated state */
  const refreshPortalDynamicData = async (): Promise<void> => {
    try {
      // If the portal info is undefined, just fetch everything instead
      if (context.value?.authentication_enabled === undefined) {
        await fetchAllPortalData()
        return
      }

      // Refresh data that is dynamic based on the user's authenticated state
      await fetchPortalCustomization()
    } catch {
      // no-op
    }
  }

  return {
    context,
    customization,
    // Methods
    fetchAllPortalData,
    refreshPortalDynamicData,
  }
})
