<template>
  <SearchResultBase data-testid="api-specs-operation">
    <template
      v-if="!!result.description"
      #default
    >
      <p data-testid="description">
        {{ result.description }}
      </p>
      <div
        class="attributes"
        data-testid="attributes"
      >
        <PortalKBadge :appearance="result.attributes.method.toLowerCase() as BadgeAppearance">
          {{ result.attributes.method }}
        </PortalKBadge>
        <div class="path">
          {{ result.attributes.path }}
        </div>
      </div>
    </template>
  </SearchResultBase>
</template>

<script setup lang="ts">
import type { BadgeAppearance } from '@kong/kongponents'

const { result } = defineProps(SEARCH_RESULT_PROPS)
const { provideSearchResult } = useSearch()

// TODO: Update the route path to view the API specs operation, once the route is available.
const resultUrl = computed(() => {
  let url = ''

  if (result.relations?.api_slug && result.attributes?.path && result.attributes?.method && result.relations?.spec_id) {
    const specString = result.attributes?.operation_id
      ? `operations/${result.attributes.operation_id}`
      : `paths${result.attributes.path.replaceAll('/', '-').replace('-', '/').replaceAll(/{|}/g, '')}/${result.attributes.method.toLowerCase()}`

    url = `/apis/${result.relations.api_slug}/specifications/${result.relations.spec_id}/${specString}`
  }

  return url
})

// Provide the search result details to the child BaseResult component
provideSearchResult({
  ...result,
  name: result.name || result.attributes?.summary || '',
  header: `${result.relations?.api_name} ${result.relations?.api_version || ''}`.trim(),
  // Provide the relative URL path in Portal.
  url: resultUrl.value,
})
</script>

<style lang="scss" scoped>
:deep(.k-badge) {
  &.method {
    border-radius: var(--kui-border-radius-round, $kui-border-radius-round);

    .badge-content {
      min-width: auto !important;
    }
  }
}

.list-item-content {

  .attributes {
    align-items: center;
    display: flex;
    gap: var(--kui-space-20, $kui-space-20);
    margin: var(--kui-space-10, $kui-space-10) $kui-space-0;

    .path {
      color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
      font-family: var(--kui-font-family-code, $kui-font-family-code);
    }
  }
}
</style>
