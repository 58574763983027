<template>
  <header class="layout-header">
    <div class="layout-header-content">
      <div class="left">
        <HeaderLogo class="layout-header-logo" />
      </div>
      <div class="center">
        <LayoutMenuHeader class="above-tablet" />
      </div>
      <div class="right">
        <!-- TODO: Enable when needed
        <HeaderPreviewMode v-if="previewModeEnabled" /> -->
        <SearchTrigger
          v-if="showSearchOnRoute"
          type="button"
        />
        <HeaderAccount />
        <LayoutMenuMobile v-if="menuItems.length || context?.authentication_enabled" />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const { context } = storeToRefs(usePortalStore())
const route = useRoute()
// const { enabled: previewModeEnabled } = usePortalPreviewMode()

const menuItems = useNavMenu('main')
const showSearchOnRoute = computed((): boolean => {
  switch (route.name) {
    case 'login-login_path':
    case 'logout':
    case 'register':
    case 'forgot-password':
    case 'reset-password':
      return false
    default:
      return true
  }
})
</script>

<style lang="scss" scoped>
.layout-header {
  align-items: center;
  background-color: var(--kui-color-background, $kui-color-background);
  border-bottom: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
  color: var(--kui-color-text-neutral-stronger, $kui-color-text-neutral-stronger);
  display: flex;
  flex: 0 0 $header-height; // must match the height
  flex-direction: column;
  height: $header-height; // must match flex height in app.vue
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 101;
}

.layout-header-logo {
  @media (max-width: ($kui-breakpoint-mobile - 1px)) {
    :deep(img) {
      max-width: 120px;
    }
  }
}

.layout-header-content {
  align-items: center;
  display: flex;
  gap: var(--kui-space-60, $kui-space-60);
  height: 100%;
  justify-content: space-between;
  padding: var(--kui-space-0, $kui-space-0) var(--kui-space-70, $kui-space-70);
  width: 100%;

  @media (min-width: $kui-breakpoint-tablet) {
    gap: var(--kui-space-70, $kui-space-70);
  }

  .left,
  .right,
  .center {
    align-items: center;
    display: flex;
    gap: var(--kui-space-50, $kui-space-50);
    height: 100%;
  }

  .left,
  .right {
    flex: 1 1 0%;
  }

  .center {
    flex-grow: 1;
    justify-content: center;
  }

  .right {
    justify-content: flex-end;
  }
}

.above-tablet {
  display: none;

  @media (min-width: $kui-breakpoint-tablet) {
    display: flex;
  }
}

.search {
  &-mobile {
    @media (min-width: $kui-breakpoint-tablet) {
      display: none;
    }
  }

  &-tablet {
    display: none;

    @media (min-width: $kui-breakpoint-tablet) {
      display: flex;
    }
  }
}
</style>
