<template>
  <LayoutBase class="error">
    <template #default>
      <LayoutBaseContent class="error-base-content">
        <PageErrorCard
          data-testid="error-page-card"
          :status-code="error.statusCode"
          text-align="center"
          :title="error.statusMessage || error.message"
        >
          <template #default>
            <div>
              <MDC
                v-if="error?.statusCode && error?.statusCode !== 404"
                class="error-output"
                :value="`\`\`\`ts\n${JSON.stringify(error, null, 2)}\n\`\`\``"
              />
              <i18n-t
                v-if="showPrivatePortal404"
                class="form-footer-text"
                keypath="errors.log_in_or_sign_up"
                scope="global"
                tag="p"
              >
                <template #log_in>
                  <NuxtLink
                    data-testid="error-log-in-link"
                    :href="{ name: 'login-login_path' }"
                  >
                    {{ t('errors.log_in_link') }}
                  </NuxtLink>
                </template>
                <template #sign_up>
                  <NuxtLink :href="{ name: 'register' }">
                    {{ t('errors.sign_up_link') }}
                  </NuxtLink>
                </template>
              </i18n-t>
              <div>
                <PortalKButton
                  v-if="showHomeLink"
                  appearance="secondary"
                  size="large"
                  @click="clearError({ redirect: context?.authentication_enabled === true && !session.authenticated ? '/login' : '/' })"
                >
                  {{ t('actions.return_to_page', { page: context?.authentication_enabled === true && !session.authenticated ? t('pages.login.title') : t('pages.home.title') }) }}
                </PortalKButton>
              </div>
            </div>
          </template>
        </PageErrorCard>
      </LayoutBaseContent>
    </template>
  </LayoutBase>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: {
    type: Object as () => NuxtError,
    default: () => null,
  },
})

const { context } = storeToRefs(usePortalStore())
const sessionStore = useSessionStore()
const { session } = storeToRefs(sessionStore)
const route = useRoute()
const { t } = useI18n()

const showPrivatePortal404 = computed((): boolean => context.value?.authentication_enabled === true && props.error.statusCode === 404 && !session.value.authenticated)

// Determine whether to show the home link based on the error statusCode
const showHomeLink = computed((): boolean => !showPrivatePortal404.value && !session.value.authenticated && props.error?.statusCode !== 401 && (props.error?.statusCode !== undefined && !props.error?.statusCode?.toString()?.startsWith('5')))

const storeReturnPath = () => {
  // If the user arrived to the page via a 404, and the portal is private, and they are not authenticated, store the return path
  if (showPrivatePortal404.value) {
    sessionStore.setLoginReturnPath(route.fullPath)
  }
}

watch(() => props.error, (err) => {
  // If the error is not a 404, log the error to the console
  if (err && err.statusCode !== 404) {
    console.error(JSON.stringify(props.error, null, 2))
  }
}, { immediate: true })

// Call one time in the root of script setup
storeReturnPath()

defineOgImage({
  component: 'OgImageOcean',
})

onUpdated(() => {
  // Store the new path if the route changes
  storeReturnPath()
})
</script>

<style lang="scss" scoped>
:deep(.layout-content-container) {
  display: flex;
}

:deep(.error-output) {
  text-align: left;
}
</style>
