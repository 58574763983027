// Get a generic `@kong/icons` interface for the option prop
import type { SearchIcon as GenericIcon } from '@kong/icons'
import {
  CodeblockIcon,
  WorkspacesIcon,
} from '@kong/icons'

/**
 * Get the icon component for a provided entity type.
 * @param {string?} entityType The entity type. If undefined, the function will return a fallback icon.
 * @returns {GenericIcon} `@kong/icons` Icon component
 */
export const getSearchResultIcon = (entityType?: string): typeof GenericIcon => {
  switch (entityType) {
    case 'api_specs_operation':
      return CodeblockIcon
    // Fallback icon
    case undefined:
    case '':
    default:
      return WorkspacesIcon
  }
}
