/**
 * This plugin is responsible for setting up the Vue app configuration.
 */
export default defineNuxtPlugin({
  name: 'app-config',
  // !Important: Datadog must be initialized before the app config is set up
  dependsOn: ['datadog'],
  async setup(nuxtApp) {
    const { datadog } = useRuntimeConfig().public
    const { $datadogRum } = useNuxtApp()

    // Since this is the first plugin, we can enable performance tracing
    nuxtApp.vueApp.config.performance = true
    // Avoid ID conflicts by giving the app an ID prefix: https://vuejs.org/api/application.html#app-config-idprefix
    nuxtApp.vueApp.config.idPrefix = 'portal'

    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
      const childComponent = instance?.$options?.__name || ''
      const childComponentDataTestId = String(instance?.$attrs?.['data-testid'] || '') || ''
      // Create the console message
      const logMessage = `'portal(errorHandler)', ${error}, 'childComponent', ${childComponent}, 'data-testid', ${childComponentDataTestId}`

      if ([true, 'true'].includes(datadog.enabled)) {
        $datadogRum.addError(error, {
          ui: {
            componentName: childComponent,
            dataTestid: childComponentDataTestId,
            info,
            source: 'nuxtApp.vueApp.config.errorHandler',
          },
        })
        // Warn in the console instead of error to prevent double-logging in Datadog
        console.warn(logMessage)
      } else {
        // Since Datadog is not enabled, log the error to the console
        console.error(logMessage)
      }

    }
  },
})
