<template>
  <div class="query-input-wrapper">
    <div class="search-inputs-wrapper">
      <div class="input-container">
        <div
          class="input-wrapper"
          :class="{ 'has-parser-error': false }"
        >
          <label
            class="screen-reader-only"
            for="portal-global-search"
          >
            {{ t('actions.search') }}
          </label>
          <KInput
            id="portal-global-search"
            ref="searchInput"
            v-model.trim="localSearchQuery"
            autocapitalize="off"
            autocomplete="off"
            class="search-input"
            :placeholder="t('actions.search')"
            type="search"
            @keydown.enter.prevent="updateQuery"
          >
            <template #before>
              <SearchIcon
                decorative
                :size="KUI_ICON_SIZE_40"
              />
            </template>
            <template #after>
              <ProgressIcon
                v-if="localSearchQuery !== '' && isLoadingResults"
                class="search-progress-icon"
                :color="KUI_COLOR_TEXT_NEUTRAL"
                decorative
                :size="KUI_ICON_SIZE_40"
                tabindex="-1"
                :title="t('search.loading_results')"
              />
              <ClearIcon
                v-else-if="localSearchQuery !== ''"
                class="search-clear-button"
                :color="KUI_COLOR_TEXT_NEUTRAL"
                role="button"
                :size="KUI_ICON_SIZE_40"
                tabindex="0"
                :title="t('actions.clear_search')"
                @click="clearSearchInput"
                @keydown.enter.prevent="clearSearchInput"
                @keydown.space.prevent="clearSearchInput"
              />
            </template>
          </KInput>
        </div>
      </div>

      <div class="close-button-wrapper">
        <div class="close-modal-icon">
          <CloseIcon
            data-testid="search-close-modal-button"
            role="button"
            :size="KUI_ICON_SIZE_50"
            tabindex="0"
            @click="emit('cancel')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SearchIcon, ProgressIcon, ClearIcon, CloseIcon } from '@kong/icons'
import { KUI_ICON_SIZE_40, KUI_ICON_SIZE_50, KUI_COLOR_TEXT_NEUTRAL } from '@kong/design-tokens'
import { useActiveElement } from '@vueuse/core'

const emit = defineEmits<{
  (e: 'update:query', query: string): void
  (e: 'cancel'): void
}>()

const { searchModalIsVisible } = storeToRefs(useLayoutStore())
const { isLoadingResults } = useSearch()
const { t } = useI18n()
const searchInput = useTemplateRef('searchInput')
// This is the local search query that is debounced and emitted to the parent component
const localSearchQuery = useState('local-search-query', () => '')

// Reset the search query when the modal is hidden
watch(searchModalIsVisible, (visible: boolean) => {
  if (!visible) {
    resetSearchQuery()
  }
})

/** Initiate the actual search, debounced */
const updateQuery = useDebounceFn(() => {
  emit('update:query', localSearchQuery.value)
}, 250)

/** Reset the search query */
const resetSearchQuery = (): void => {
  // Clear search query
  localSearchQuery.value = ''
  // Emit the empty value
  updateQuery()
}

/** Focus the search input */
const focusSearchInput = (): void => {
  searchInput.value?.$el.querySelector('input')?.focus()
}

const clearSearchInput = async (): Promise<void> => {
  resetSearchQuery()

  await nextTick()
  // Re-focus on the input after clearing the search terms
  focusSearchInput()
}

// Access the currently focused element
const activeElement = useActiveElement()

const kbInterceptor = (e: KeyboardEvent) => {
  // If the search input is not focused and user presses Cmd/Ctrl+K, focus on the input
  if (activeElement.value !== searchInput.value && e.code === 'KeyK' && (e.ctrlKey || e.metaKey)) {
    e.preventDefault()
    focusSearchInput()
  }
}

onBeforeMount(() => {
  document.addEventListener('keydown', kbInterceptor)
  // Always reset the search query when the component is mounted
  localSearchQuery.value = ''
})

onMounted(async ():Promise<void> => {
  await nextTick()
  focusSearchInput()
})

onUnmounted(() => {
  document.removeEventListener('keydown', kbInterceptor)
})
</script>

<style lang="scss" scoped>

// outer div wrapping entire construction of input and geo selector and suggestion dropdown
.query-input-wrapper {
  box-sizing: border-box;
  display: inline-block;
  padding: var(--kui-space-30, $kui-space-30) var(--kui-space-60, $kui-space-60);
  position: relative;
  width: 100%;

  // outer div wrapping input and geo selector
  .search-inputs-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    gap: var(--kui-space-40, $kui-space-40);
    justify-content: center;
    margin-bottom: var(--kui-space-10, $kui-space-10);

    @media (min-width: $kui-breakpoint-phablet) {
      flex-direction: row;
    }

    :deep(.k-input input) {
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    }

    .close-button-wrapper {
      align-items: center;
      display: flex;
      flex: 1 1 0;
      gap: var(--kui-space-40, $kui-space-40);
      justify-content: flex-end;
      width: 100%;
    }

    .close-modal-icon {
      color: var(--kui-color-text-neutral, $kui-color-text-neutral);

      @media (min-width: $kui-breakpoint-mobile) {
        display: none;
      }
    }

    .region-select-control {
      user-select: none; // Prevent user from highlighting text in the segmented control
    }

    // outer container for  input (holds search icon, input and clear icon)
    .input-container {
      align-items: center;
      border: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border-transparent, $kui-color-border-transparent);
      border-radius: var(--kui-border-radius-round, $kui-border-radius-round);
      box-shadow: var(--kui-shadow-border, $kui-shadow-border);
      box-sizing: border-box; // contain borders within element
      display: flex;
      flex-direction: row;
      justify-content: center;
      transition: all 0.2s ease-in-out;
      width: 100%;

      .search-progress-icon,
      .search-clear-button {
        color: var(--kui-color-text-neutral, $kui-color-text-neutral) !important;
        margin: var(--kui-space-0, $kui-space-0) var(--kui-space-40, $kui-space-40);
      }

      .search-clear-button {
        border-radius: var(--kui-border-radius-round, $kui-border-radius-round);
        cursor: pointer;
        transition: color 0.2s ease-in-out;

        &:focus-visible {
          box-shadow: var(--kui-shadow-focus, $kui-shadow-focus);
          color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong) !important;
        }

        &:hover {
          color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong) !important;
        }

        &:active {
          color: var(--kui-color-text-neutral-stronger, $kui-color-text-neutral-stronger) !important;
        }

        &:focus {
          outline: none;
        }
      }

      // outer container for the input itself - holds presenting (highlighted) and transparent  (editable) overlaying divs
      .input-wrapper {
        align-items: center;
        border: 0;
        display: inline-block;
        font-size: var(--kui-font-size-40, $kui-font-size-40);
        position: relative;
        width: 100%;
      }
    }
  }
}

.screen-reader-only {
  border-width: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: var(--kui-space-0, $kui-space-0);
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
</style>
