<template>
  <KButton
    v-if="typeof to === 'undefined'"
    v-bind="computedProps"
  >
    <MDCSlot
      name="default"
      unwrap="p"
    />
  </KButton>
  <NuxtLink
    v-else
    v-slot="{ href, navigate }"
    custom
    :external="external"
    :to="to"
  >
    <KButton
      v-bind="computedProps"
      :to="href"
      @click.prevent="onClick(navigate, href)"
    >
      <MDCSlot
        name="default"
        unwrap="p"
      />
    </KButton>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { PortalKButtonProps } from '#imports'
import { ButtonAppearances, ButtonSizes } from '@kong/kongponents'
import type { ButtonAppearance, ButtonSize } from '@kong/kongponents'

const {
  // KButton props
  appearance = 'primary',
  size = 'medium',
  to = undefined,
  icon = false,
  disabled = false,
  // Custom props
  external = false,
  // Shared props
  background = null,
  backgroundColor = null,
  border = null,
  borderRadius = null,
  color = null,
  display = null,
  margin = null,
  padding = null,
  styles = '',
} = defineProps<PortalKButtonProps>()

const attrs = useAttrs()
// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), attrs.id as string)

// Compute the appearance and size based on the provided props
const computedAppearance = computed((): ButtonAppearance => Object.values(ButtonAppearances).includes(appearance) ? appearance : 'primary')
const computedSize = computed((): ButtonSize => Object.values(ButtonSizes).includes(size) ? size : 'medium')

const computedStyles = computed(() => ({
  backgroundColor,
  // Background should come _after_ backgroundColor
  background,
  border,
  borderRadius,
  color,
  display,
  margin,
  padding,
}))

const computedProps = computed(() => ({
  ...attrs,
  id: componentId,
  appearance: computedAppearance.value,
  class: 'portal-k-button',
  'data-testid': 'data-testid' in attrs ? attrs['data-testid'] : 'portal-k-button',
  disabled: disabled,
  icon: icon,
  size: computedSize.value,
  style: computedStyles.value,
  target: external ? '_blank' : undefined,
}))

const onClick = (navigate: () => void, href: string): void => {
  if (!!href && typeof navigate === 'function') {
    navigate()
  }
}
</script>
