import { useEventListener } from '@vueuse/core'

export default defineNuxtPlugin({
  name: 'preview-mode',
  parallel: true,
  setup() {
    const { enabled: previewModeEnabled, state, updatePage, updateSnippet, updateTheme, updateGlobalCss, exit } = usePortalPreviewMode()
    const inIframe = isPortalInIframe()
    const router = useRouter()

    // Whenever preview mode is enabled, and in an iframe or there is a window.opener,
    // postMessage to the parent window whenever the route changes.
    if (previewModeEnabled.value && (inIframe || window?.opener)) {
      router.afterEach((to) => {
        postPreviewModeMessage({ action: 'portal:preview:path', path: to.fullPath })
      })
    }

    /**
     * Determine if the app is running inside an iframe (likely because of previewMode)
     * If not in an iframe or window.opener, exit early and do not process events.
     *
     * TODO: This is a temporary solution to prevent preview mode from being enabled in
     * the wrong context and should be refactored or removed once persistent previews are supported.
     * There is similar logic in `layers/core/middleware/01.preview-mode.global.ts`.
     */
    if (previewModeEnabled.value && !(window.opener || inIframe)) {
      return
    }

    /**
     * Process a message event for portal preview mode.
     *
     * @param {MessageEvent<PostPortalStudioMessageData>} evt - The message event to process.
     * @returns {boolean} - Whether the message event should be re-broadcasted to same-domain tabs.
     */
    function processMessageEvent(evt: MessageEvent<PostPortalStudioMessageData>): void {
      // If preview mode is not enabled,
      // or there is an event origin and the event is not from Konnect, the same origin, or `localhost`,
      // then ignore the message
      if (!previewModeEnabled.value || (!!evt.origin && !['konghq.com', 'konghq.tech', 'localhost'].some((origin: string) => evt.origin.includes(origin)))) {
        return
      }

      const { preview_id, path, snippet_name, action, content, theme, css } = evt.data

      // If exiting preview mode, disable it and exit early if the preview_id matches.
      // The `usePortalPreviewMode` composable will handle the rest.
      if (action === 'portal:preview:exit' && preview_id === state.value.preview_id) {
        exit()
        return
      }

      // If the message action is not `portal:preview:update`, ignore the message
      if (action !== 'portal:preview:update') {
        return
      }

      // If the message preview_id is not a valid UUID, or not the correct preview_id, ignore the message
      if (!isValidUUID(preview_id) || (!!state.value.preview_id && state.value.preview_id !== preview_id)) {
        console.error('Invalid preview `preview_id`', preview_id)
        return
      }

      if (typeof theme === 'object') {
        // Store the new theme config
        updateTheme(theme)
        return
      }

      if (typeof css === 'string') {
        // Store the new global CSS content
        updateGlobalCss(css)
        return
      }

      // If the message path content is not a string, ignore the message
      if (typeof content !== 'string') {
        console.error('Invalid preview `content`', content)
        return
      }

      if (!!path && !!snippet_name) {
        console.error('Invalid parameters: both `path` and `snippet_name` cannot be provided')
        return
      }

      if (!path && !snippet_name) {
        console.error('Invalid parameters: one of `path` or `snippet_name` must be provided')
        return
      }

      if (!!path && path.startsWith('http')) {
        console.error('Invalid preview `path`', path)
        return
      }

      if (!!snippet_name && snippet_name.startsWith('/')) {
        console.error('Invalid preview `snippet_name`', snippet_name)
        return
      }

      // Store the new page content
      if (path) {
        updatePage(path, content)
        return
      }

      // Store the new snippet content
      if (snippet_name) {
        updateSnippet(snippet_name, content)
        return
      }
    }

    if (import.meta.client) {
      useEventListener(window, 'message', (evt: MessageEvent<PostPortalStudioMessageData>) => {
        // Process the message event
        processMessageEvent(evt)
      })
    }
  },
})
