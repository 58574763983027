
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as logoutc_jNG7i1rqCtjqhx_o31qSCqQJQARj9IDKxERl8a6pEMeta } from "/work/portal/portal/layers/core/app/pages/logout.vue?macro=true";
import { default as registertNB7NSORGJiE18H_9N4O_45xAJCdxLw7VIZJ4aGxulsu4Meta } from "/work/portal/portal/layers/core/app/pages/register.vue?macro=true";
import { default as indexDISLYsyWOb6I9bwDXI8DTKuQNicGhm2ec5saNTkmPKgMeta } from "/work/portal/portal/layers/core/app/pages/apps/index.vue?macro=true";
import { default as apis0ZAF_455Aa8XJhT2f4Amov9p5A2mBRbmytwrk_45YGS3fdYMeta } from "/work/portal/portal/layers/core/app/pages/apps/[app_id]/apis.vue?macro=true";
import { default as analyticsljkosp1nivWx3eVVapc4l83M8lbyfqmNambQwFAVya4Meta } from "/work/portal/portal/layers/core/app/pages/apps/[app_id]/analytics.vue?macro=true";
import { default as credentialsR7WHqSH7sQs6mBSA7R4WnjLUgTxPcTOSYNuOZCBhRZMMeta } from "/work/portal/portal/layers/core/app/pages/apps/[app_id]/credentials.vue?macro=true";
import { default as _91app_id_93qXFXcQ642ChumamwhSjY10a67r21lT0m3VRExB4BEJEMeta } from "/work/portal/portal/layers/core/app/pages/apps/[app_id].vue?macro=true";
import { default as _91_46_46_46page_slug_93O3t5br7m552ntwtxC9bQOHzkeBRy93D7RbVB_4Xkt_45sMeta } from "/work/portal/portal/layers/core/app/pages/[...page_slug].vue?macro=true";
import { default as reset_45password_84037srjhJ8bN4dIkY3cSLinNkVR6REDD0Sal5l0P4Meta } from "/work/portal/portal/layers/core/app/pages/reset-password.vue?macro=true";
import { default as indexeuHjzdmCxwW33BN78PmN_45GQCLAEcDsYt3_k_45HyY12pUMeta } from "/work/portal/portal/layers/core/app/pages/apis/[api_slug]/docs/index.vue?macro=true";
import { default as _91_46_46_46document_slug_93_sR_hdMAPy_45gY2ji9I2wpIIEY1pc3gVknFtS5hzItTQMeta } from "/work/portal/portal/layers/core/app/pages/apis/[api_slug]/docs/[...document_slug].vue?macro=true";
import { default as _91_46_46_46spec_path_93p3Yj8i89w9hRGl3OiEus1k9yQ0RlTQvGexq1w2Q7BA0Meta } from "/work/portal/portal/layers/core/app/pages/apis/[api_slug]/specifications/[spec_id]/[...spec_path].vue?macro=true";
import { default as _91api_slug_93KdeoTO5Ay8CQO_fSopdN3Y3C4WSGKpOD_iiHN1smJ24Meta } from "/work/portal/portal/layers/core/app/pages/apis/[api_slug].vue?macro=true";
import { default as forgot_45passworde8wT_45jiKdcnPYN6Gt9RhYbnihUi8wFW1gPILh4wCTuAMeta } from "/work/portal/portal/layers/core/app/pages/forgot-password.vue?macro=true";
import { default as _91_46_46_46login_path_93gCk9aup8llppGcEy3xXhAK8Isl_45tq6bqPnkVkeq7lRQMeta } from "/work/portal/portal/layers/core/app/pages/login/[...login_path].vue?macro=true";
import { default as _91snippet_name_93_zxbPxJtWJ1kbvu9r9aNQhRjPUpda40_IcmP9PWhEw8Meta } from "/work/portal/portal/layers/core/app/pages/_preview-mode/snippets/[snippet_name].vue?macro=true";
import { default as component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4Meta } from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4 } from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "logout",
    path: "/logout",
    component: () => import("/work/portal/portal/layers/core/app/pages/logout.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/work/portal/portal/layers/core/app/pages/register.vue")
  },
  {
    name: "apps",
    path: "/apps",
    component: () => import("/work/portal/portal/layers/core/app/pages/apps/index.vue")
  },
  {
    name: "apps-app_id",
    path: "/apps/:app_id()",
    component: () => import("/work/portal/portal/layers/core/app/pages/apps/[app_id].vue"),
    children: [
  {
    name: "apps-app_id-apis",
    path: "apis",
    component: () => import("/work/portal/portal/layers/core/app/pages/apps/[app_id]/apis.vue")
  },
  {
    name: "apps-app_id-analytics",
    path: "analytics",
    component: () => import("/work/portal/portal/layers/core/app/pages/apps/[app_id]/analytics.vue")
  },
  {
    name: "apps-app_id-credentials",
    path: "credentials",
    meta: credentialsR7WHqSH7sQs6mBSA7R4WnjLUgTxPcTOSYNuOZCBhRZMMeta || {},
    component: () => import("/work/portal/portal/layers/core/app/pages/apps/[app_id]/credentials.vue")
  }
]
  },
  {
    name: "page_slug",
    path: "/:page_slug(.*)*",
    meta: _91_46_46_46page_slug_93O3t5br7m552ntwtxC9bQOHzkeBRy93D7RbVB_4Xkt_45sMeta || {},
    component: () => import("/work/portal/portal/layers/core/app/pages/[...page_slug].vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/work/portal/portal/layers/core/app/pages/reset-password.vue")
  },
  {
    name: "apis-api_slug",
    path: "/apis/:api_slug()",
    meta: _91api_slug_93KdeoTO5Ay8CQO_fSopdN3Y3C4WSGKpOD_iiHN1smJ24Meta || {},
    component: () => import("/work/portal/portal/layers/core/app/pages/apis/[api_slug].vue"),
    children: [
  {
    name: "apis-api_slug-docs",
    path: "docs",
    meta: indexeuHjzdmCxwW33BN78PmN_45GQCLAEcDsYt3_k_45HyY12pUMeta || {},
    component: () => import("/work/portal/portal/layers/core/app/pages/apis/[api_slug]/docs/index.vue")
  },
  {
    name: "apis-api_slug-docs-document_slug",
    path: "docs/:document_slug(.*)*",
    meta: _91_46_46_46document_slug_93_sR_hdMAPy_45gY2ji9I2wpIIEY1pc3gVknFtS5hzItTQMeta || {},
    component: () => import("/work/portal/portal/layers/core/app/pages/apis/[api_slug]/docs/[...document_slug].vue")
  },
  {
    name: "apis-api_slug-specifications-spec_id-spec_path",
    path: "specifications/:spec_id()/:spec_path(.*)*",
    component: () => import("/work/portal/portal/layers/core/app/pages/apis/[api_slug]/specifications/[spec_id]/[...spec_path].vue")
  }
]
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/work/portal/portal/layers/core/app/pages/forgot-password.vue")
  },
  {
    name: "login-login_path",
    path: "/login/:login_path(.*)*",
    meta: _91_46_46_46login_path_93gCk9aup8llppGcEy3xXhAK8Isl_45tq6bqPnkVkeq7lRQMeta || {},
    component: () => import("/work/portal/portal/layers/core/app/pages/login/[...login_path].vue")
  },
  {
    name: "_preview-mode-snippets-snippet_name",
    path: "/_preview-mode/snippets/:snippet_name()",
    component: () => import("/work/portal/portal/layers/core/app/pages/_preview-mode/snippets/[snippet_name].vue")
  },
  {
    name: component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4Meta?.name,
    path: "/products",
    component: component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4
  },
  {
    name: component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4Meta?.name,
    path: "/products/:pathMatch(.*)",
    component: component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4
  },
  {
    name: component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4Meta?.name,
    path: "/my-apps",
    component: component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4
  },
  {
    name: component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4Meta?.name,
    path: "/my-apps/:pathMatch(.*)",
    component: component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4
  },
  {
    name: component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubtwPpV0MscS8pHCFLw9yajIfpjPuL8d9N6Yb_cjSCkD4
  }
]