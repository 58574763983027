<template>
  <aside
    class="layout-sidebar"
    :class="[computedAlign, { 'mobile-full-width': mobileFullWidth }]"
  >
    <slot name="header" />

    <div class="sidebar-content">
      <slot name="default" />
    </div>
  </aside>
</template>

<script setup lang="ts">
const props = defineProps({
  /** Should the sidebar be aligned to the left or the right of the page */
  align: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
    validator: (pos: 'left' | 'right'): boolean => ['left', 'right'].includes(pos),
  },
  /** Should the sidebar be full width on mobile? Typically `false` unless the primary mobile menu */
  mobileFullWidth: {
    type: Boolean,
    default: false,
  },
})

const computedAlign = computed((): 'left' | 'right' => props.align === 'right' ? 'right' : 'left')
</script>

<style lang="scss" scoped>
.layout-sidebar {
  align-self: flex-start;
  background-color: var(--kui-color-background, $kui-color-background);
  border-right: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
  color: var(--kui-color-text, $kui-color-text);
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  min-width: $sidebar-width;
  padding: var(--kui-space-0, $kui-space-0);
  position: fixed;
  top: 0;
  transform: translateX(-#{$sidebar-width});
  transition: transform 0.2s ease-in-out;
  width: $sidebar-width;
  will-change: transform;
  z-index: 1000;

  &.right {
    border-right: none;
    left: unset;
    right: 0;
    transform: translateX(#{$sidebar-width});

    @media (min-width: $kui-breakpoint-tablet) {
      right: unset;
      transform: none;
    }
  }

  // Allow the sidebar to be full width on mobile
  &.mobile-full-width {
    @media (max-width: ($kui-breakpoint-tablet - 1px)) {
      border-right: none;
      width: 100%;
    }

    &.left {
      @media (max-width: ($kui-breakpoint-tablet - 1px)) {
        left: 0;
        transform: translateX(-100%);
      }
    }

    &.right {
      @media (max-width: ($kui-breakpoint-tablet - 1px)) {
        right: 0;
        transform: translateX(100%);
      }
    }
  }

  // Sticky sidebar on larger screens
  @media (min-width: $kui-breakpoint-tablet) {
    display: flex;
    height: calc(100vh - #{$header-height});
    left: unset;
    position: sticky;
    right: unset;
    top: $header-height;
    transform: none;
    z-index: 1;
  }

  @media (max-width: ($kui-breakpoint-tablet - 1px)) {
    // Show the sidebar when open on mobile
    &.open {
      bottom: 0;
      left: 0;
      position: fixed;
      top: 0;
      transform: translateX(0) !important;

      &.right {
        left: unset;
        right: 0;
      }
    }
  }
}

/** !Important: this class is bound in `PageMdcContent.vue` */
.sidebar-content {
  display: block;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  width: 100%;
}

.close-button {
  align-items: center;
  background-color: var(--kui-color-background-transparent, $kui-color-background-transparent);
  border: none;
  color: var(--kui-color-text-neutral, $kui-color-text-neutral);
  cursor: pointer;
  display: inline-flex;
  gap: var(--kui-space-20, $kui-space-20);
  justify-content: center;
  padding: var(--kui-space-0, $kui-space-0);
}
</style>
