<template>
  <div
    :aria-controls="PORTAL_SEARCH_MODAL_ID"
    :aria-label="t('actions.toggle_search')"
    class="search-button"
    :class="[computedType]"
    data-testid="search-trigger-button"
    role="button"
    tabindex="0"
    type="button"
    @click.stop="searchModalIsVisible = !searchModalIsVisible"
    @keydown.enter.stop="searchModalIsVisible = !searchModalIsVisible"
    @keydown.space.stop="searchModalIsVisible = !searchModalIsVisible"
  >
    <div class="search-left">
      <SearchIcon
        decorative
        :size="KUI_ICON_SIZE_50"
      />
      <div class="search-text">
        {{ t('actions.search') }}
      </div>
    </div>
    <div
      class="search-shortcut"
      :class="{ 'mac': isMac || isMac === undefined }"
    >
      <kbd
        v-if="isMac === undefined"
        class="search-command"
      />
      <SearchCommand
        @mac="(mac: boolean) => isMac = mac"
        @search="onSearchShortcut"
      />
      <kbd>K</kbd>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SearchIcon } from '@kong/icons'
import { KUI_ICON_SIZE_50 } from '@kong/design-tokens'

const props = defineProps({
  type: {
    type: String as PropType<'button' | 'input'>,
    default: 'button',
  },
})

const { searchModalIsVisible } = storeToRefs(useLayoutStore())
const { t } = useI18n()

// Initialize as undefined in order to prevent the flash of the client component
const isMac = useState<boolean | undefined>(() => undefined)
const onSearchShortcut = (): void => {
  // If the modal is not visible and user presses Cmd/Ctrl+K, open the search modal
  if (!searchModalIsVisible.value) {
    searchModalIsVisible.value = true
  }
}

// Compute the type of the trigger
const computedType = computed((): 'type-button' | 'type-input' => props.type === 'input' ? 'type-input' : 'type-button')
</script>

<style lang="scss" scoped>
.search-button {
  align-items: center;
  // No background color on mobile
  background-color: var(--kui-color-background-transparent, $kui-color-background-transparent);
  border: 0;
  border-radius: var(--kui-border-radius-round, $kui-border-radius-round);
  color: var(--kui-color-text-neutral, $kui-color-text-neutral);
  cursor: pointer;
  display: flex;
  font-size: var(--kui-font-size-30, $kui-font-size-30);
  gap: var(--kui-space-40, $kui-space-40);
  padding: var(--kui-space-0, $kui-space-0);
  transition: background-color 0.3s ease-in-out;
  user-select: none;

  @media (min-width: $kui-breakpoint-tablet) {
    background-color: var(--kui-color-background-neutral-weakest, $kui-color-background-neutral-weakest);
    border-radius: var(--kui-border-radius-30, $kui-border-radius-30);
    box-shadow: var(--kui-shadow-border, $kui-shadow-border);
    height: 40px;
    justify-content: space-between;
    max-width: 340px;
    min-width: 140px;
    padding: var(--kui-space-30, $kui-space-30);
    width: 100%;
  }

  &:focus-visible {
    box-shadow: var(--kui-shadow-focus, $kui-shadow-focus);
    outline: none;
  }

  &:hover,
  &:focus-visible {
    background-color: var(--kui-color-background-neutral-weaker, $kui-color-background-neutral-weaker);
  }

  &, // Include parent container
  .search-left {
    align-items: center;
    color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
    display: flex;
    gap: var(--kui-space-40, $kui-space-40);
  }

  .search-left {
    color: var(--kui-color-text-primary, $kui-color-text-primary);

    @media (min-width: $kui-breakpoint-tablet) {
      color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
    }
  }

  .search-text {
    display: none;
    font-family: var(--kui-font-family-text, $kui-font-family-text);
    line-height: 1;
    padding-right: var(--kui-space-30, $kui-space-30);
    white-space: nowrap;

    // We only display the icon on mobile
    @media (min-width: $kui-breakpoint-tablet) {
      display: block;
    }
  }

  .search-shortcut {
    align-items: center;
    border-radius: var(--kui-border-radius-20, $kui-border-radius-20);
    color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
    display: none; // Hide keyboard shortcut on mobile
    gap: var(--kui-space-30, $kui-space-30);
    line-height: 1;
    padding: var(--kui-space-20, $kui-space-20) var(--kui-space-30, $kui-space-30);
    transition: background-color 0.3s ease-in-out;

    @media (min-width: $kui-breakpoint-tablet) {
      display: flex;
    }

    :deep(.search-command:before),
    kbd {
      font-family: var(--kui-font-family-text, $kui-font-family-text);
      font-size: var(--kui-font-size-30, $kui-font-size-30);
    }

    :deep(.search-command:before) {
      content: 'Ctrl';
    }

    &.mac {
      gap: var(--kui-space-10, $kui-space-10);

      :deep(.search-command:before) {
        content: '\2318';
        line-height: 0;
        position: relative;
      }
    }
  }

  // Button `type` overrides
  &.type-button {
    border-radius: var(--kui-border-radius-round, $kui-border-radius-round);
    color: var(--kui-color-text-neutral, $kui-color-text-neutral);
    width: auto;

    @media (min-width: $kui-breakpoint-tablet) {
      background-color: var(--kui-color-background-neutral-weakest, $kui-color-background-neutral-weakest);
      border-radius: var(--kui-border-radius-30, $kui-border-radius-30);
      box-shadow: var(--kui-shadow-border, $kui-shadow-border);
      padding: var(--kui-space-30, $kui-space-30);
    }

    &:focus-visible {
      box-shadow: var(--kui-shadow-focus, $kui-shadow-focus);
      outline: none;
    }

    &:hover,
    &:focus-visible {
      background-color: var(--kui-color-background-primary-weakest, $kui-color-background-primary-weakest);

      @media (min-width: $kui-breakpoint-tablet) {
        background-color: var(--kui-color-background-neutral-weaker, $kui-color-background-neutral-weaker);
      }
    }
  }
}
</style>
