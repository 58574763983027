<template>
  <Teleport to="#teleports">
    <KModal
      :id="PORTAL_SEARCH_MODAL_ID"
      class="portal-search-modal"
      :class="{ 'limit-modal-height': searchResultsLoading || zeroSearchResults || !searchQuery }"
      close-on-backdrop-click
      close-on-escape
      data-testid="portal-search-modal"
      :hide-cancel-button="true"
      max-height="90vh"
      max-width="820px"
      role="search"
      title=""
      :visible="true"
      @cancel="hideModal"
    >
      <template #content>
        <SearchModalInput
          @cancel="hideModal"
          @update:query="startSearch"
        />

        <div class="tab-container">
          <SearchResults
            @loading="toggleLoadingState"
            @result-count="updateResultCount"
          />
        </div>

        <SearchModalFooter />
      </template>
    </KModal>
  </Teleport>
</template>

<script setup lang="ts">
const { searchModalIsVisible } = storeToRefs(useLayoutStore())
const searchQuery = useState(PORTAL_SEARCH_QUERY_KEY, () => '')

const hideModal = async () => {
  // Reset the search query
  searchQuery.value = ''
  await nextTick()
  searchModalIsVisible.value = false
}

const startSearch = async (newSearchQuery: string) => {
  searchQuery.value = newSearchQuery
}

const searchResultsLoading = useState<boolean>('search-results-loading', () => false)
const toggleLoadingState = (loading: boolean): void => {
  searchResultsLoading.value = loading
}

const zeroSearchResults = useState<boolean>('zero-search-results', () => false)
const updateResultCount = (count: number): void => {
  zeroSearchResults.value = count === 0
}
</script>

<style lang="scss" scoped>
.portal-search-modal {
  // Limit the height when loading or no results
  &.limit-modal-height {
    :deep(.modal-container.custom-content) {
      max-height: 640px; // prevent a huge modal while the loading skeleton is visible or showing empty results

      .tab-container {
        /* stylelint-disable-next-line @kong/design-tokens/use-proper-token */
        height: calc(100% - 46px - calc(#{var(--kui-space-10, $kui-space-10)} * 2)); // 100% minus the ul[role="tablist"] container height, minus 2 * the padding
      }
    }
  }

  :deep() {
    .modal-container.custom-content {
      /* stylelint-disable-next-line @kong/design-tokens/use-proper-token */
      height: calc(100% - #{var(--kui-space-70, $kui-space-70)}); // 100% minus the padding-top of the .modal-backdrop element

      @media (min-width: $kui-breakpoint-phablet) {
        /* stylelint-disable-next-line @kong/design-tokens/use-proper-token */
        height: calc(100% - #{var(--kui-space-110, $kui-space-110)}); // 100% minus the padding-top of the .modal-backdrop element
      }
    }

    .k-tabs {
      // Override tab ul margins and handle them in the scrollable .tab-container instead.
      ul[role="tablist"] {
        margin-bottom: var(--kui-space-0, $kui-space-0);
        margin-top: var(--kui-space-0, $kui-space-0);
      }
    }

    .tab-container {
      background-color: var(--kui-color-background-neutral-weakest, $kui-color-background-neutral-weakest);
      border-top: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
      box-sizing: content-box; // resolves a box-sizing issue in Konnect
      /* stylelint-disable-next-line @kong/design-tokens/use-proper-token */
      height: calc(100% - 50px - calc(#{var(--kui-space-80, $kui-space-80)} * 2)); // 100% minus the ul[role="tablist"] container height, minus 2 * the padding
      overflow-x: hidden;
      overflow-y: auto; // Allow scrolling the result list
      padding: var(--kui-space-80, $kui-space-80) var(--kui-space-60, $kui-space-60);
      scrollbar-width: thin;

      @media (min-width: $kui-breakpoint-mobile) {
        padding: var(--kui-space-80, $kui-space-80);
      }
    }
  }
}
</style>
