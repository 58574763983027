/** Post a message to the parent window(s) defaulting to allowing all origins */
export const postPreviewModeMessage = ({ action, origin = '*', ...otherData }: PostPreviewModeMessageData): void => {
  // If not in the client, exit early
  if (!import.meta.client) {
    return
  }

  const inIframe = isPortalInIframe()

  // If in an iframe, postMessage to the parent window that the app is ready to receive messages
  if (inIframe) {
    window.parent.postMessage({ action, ...otherData }, origin)
  }

  // Window was opened with window.open, postMessage to the parent window that the app is ready to receive messages
  if (window.opener) {
    window.opener.postMessage({ action, ...otherData }, origin)
  }
}
