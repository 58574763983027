export default defineNuxtPlugin({
  name: 'custom-scripts',
  dependsOn: ['datadog', 'fetch-portal-data', 'launch-darkly'],
  parallel: true,
  async setup() {
    const { customization } = storeToRefs(usePortalStore())
    const { $getFeatureFlag } = useNuxtApp()
    const customJavaScriptEnabled = await $getFeatureFlag<boolean>(FeatureFlags.CustomJavaScript, false)

    // If the Custom JavaScript feature flag is not enabled, exit early
    if (!customJavaScriptEnabled) {
      return
    }

    const scripts: Record<string, string | number | boolean>[] = []

    const testScriptUrl = (url: string = ''): boolean => {
      const regex = /^https:\/\/.+/

      return regex.test(url)
    }

    const sanitizeUrls = (arr: string[]): Set<string> => {
      return new Set(arr.filter(el => el))
    }

    // Set the scriptUrls variable to the js?.scripts property from the customization object
    let scriptUrls = (customization.value?.js?.scripts || []).filter((script: string) => {
      if (testScriptUrl(script)) {
        return script.trim()
      }
    })

    scriptUrls = Array.from(sanitizeUrls(scriptUrls))

    if (scriptUrls.length > 0) {
      scriptUrls.forEach((script: string) => {
        // remove 'https://' and undesired characters from key
        const scriptKey = `portal-custom-js-url-${script.replace('http(?:s)?://', '').replace(/[^a-zA-Z0-9-_]/g, '-')}`
        scripts.push({
          key: scriptKey,
          'data-testid': scriptKey,
          src: script,
          // Since we're enforcing `defer: true` and `async: true` we inject into the `head`
          tagPosition: 'head',
          tagPriority: 1000,
          defer: true,
          async: true,
        })
      })
    }

    // Set the customScript variable to the js?.custom property from the customization object
    const customScript = removeHtmlTags(String(customization.value?.js?.custom || '').trim(), ['script', 'style'])
    if (customScript) {
      scripts.push({
        key: 'portal-custom-js',
        'data-testid': 'portal-custom-js',
        textContent: customScript,
        tagPosition: 'head',
        tagPriority: 1000,
      })
    }

    useHead({
      script: scripts,
    }, {
      mode: 'client',
    })
  },
})
