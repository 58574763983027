import { SearchResultTypeFallback, SearchResultTypeApiSpecsOperation } from '#components'

const SEARCH_RESULT_COMPONENTS_MAP = new Map()
  .set('fallback', SearchResultTypeFallback)
  // Set the component for the `api_specs_operation` entity type
  .set('api_specs_operation', SearchResultTypeApiSpecsOperation)

export default function useSearch() {
  const isLoadingResults = useState<boolean>('portal-search-loading-results', () => false)
  const isLoadingNextPageResults = useState<boolean>('portal-search-loading-next-results', () => false)
  const fetchError = useState<boolean>('portal-search-error', () => false)
  const searchResults = useState<PortalApiResponseTemp<'search-portal'>['data']>('portal-search-results', () => [])
  const searchResultsMeta = useState<PortalApiResponseTemp<'search-portal'>['meta'] | undefined>('portal-search-results-meta', () => undefined)
  const nextPageCursor = useState<string>('portal-search-next-page-cursor', () => '')
  const hasNextPage = computed((): boolean => !!nextPageCursor.value)
  const searchQuery = useState(PORTAL_SEARCH_QUERY_KEY, () => '')

  const { $portalApi } = useNuxtApp()

  // Define changeable variables
  let lastItemSearched: string
  let lastSearchResults: PortalApiResponseTemp<'search-portal'>['data'] | undefined
  let lastSearchResultsMeta: PortalApiResponseTemp<'search-portal'>['meta'] | undefined
  /**
   * Focus on the previous/next search result when the user presses `up` or `down` arrow on their keyboard.
   * @param {'up' | 'down'} direction The direction the user pressed
   * @param {KeyboardEvent} e The keydown event
   */
  const focusListItem = (direction: 'up' | 'down', e: KeyboardEvent): void => {
    if (!import.meta.client) return

    const currentLink: HTMLAnchorElement | null = e.target as HTMLAnchorElement

    let newLink: HTMLAnchorElement | null | undefined

    if (direction === 'up') {
      newLink = currentLink?.parentElement?.previousElementSibling?.querySelector('a')
    } else {
      newLink = currentLink?.parentElement?.nextElementSibling?.querySelector('a')
    }

    // Scroll the element into view and focus
    if (newLink) {
      newLink.scrollIntoView({ behavior: 'smooth', block: 'center' })
      newLink.focus()
    }
  }

  /**
   * Get the search result component for a provided entity type.
   * @param {string?} entityName The entity type. If undefined, the function will return a fallback icon.
   */
  const getSearchResultComponent = (entityName: string) => {
    if (entityName && SEARCH_RESULT_COMPONENTS_MAP.has(entityName)) {
      return SEARCH_RESULT_COMPONENTS_MAP.get(entityName)
    }

    // Last resort, return the fallback search result component which will call `console.error` and report to DataDog
    return SearchResultTypeFallback
  }

  /**
   * Provide the search result to be injected into the child BaseResult.vue component.
   * @param {InjectedSearchResult} injectedResult The search result object with additional client-side properties. If the `url` property is not provided, it will automatically be generated in the composable.
   */
  const provideSearchResult = (injectedResult: InjectedSearchResult): void => {
    if (!injectedResult) {
      return
    }

    const searchResult = { ...injectedResult }

    // modify the searchResult object, if needed

    if (searchResult.url) {
      // Ensure the url starts with a slash
      if (!searchResult.url.startsWith('/')) {
        searchResult.url = '/' + searchResult.url
      }
    }

    // Provide to the child BaseResult.vue component
    provide<InjectedSearchResult>(SearchResultInjectionKey, searchResult)
  }

  /**
   * Fetch the results from the KSearch API
   * @param {string} queryString The user's search query
   * @param {boolean} fetchNextPage Should we fetch the next page. Defaults to `false`
   * @returns {Promise<void>}
   */
  const fetchSearchResults = async (searchQuery: string, fetchNextPage: boolean = false): Promise<void> => {
    try {
      fetchError.value = false

      if (!searchQuery) {
        searchResults.value = []
        return
      }

      if (lastItemSearched === searchQuery && !fetchNextPage) {
        searchResults.value = lastSearchResults || []
        searchResultsMeta.value = lastSearchResultsMeta
        return
      }

      // Trigger loading state
      isLoadingResults.value = true

      // Should we fetch the next page or search?
      const shouldFetchNextPage: boolean = fetchNextPage && hasNextPage.value
      // Signal that we are loading the next page; used to control the state of existing results in the UI
      if (shouldFetchNextPage) {
        isLoadingNextPageResults.value = true
      }

      // Perform the search request
      // Determine the search URL and params based on if we are fetching the next page
      // @ts-ignore - type warning
      const { data: fetchedResults, meta }: { data: PortalApiResponseTemp<'search-portal'>['data'], meta: PortalApiResponseTemp<'search-portal'>['meta'] } = await $portalApi(`${shouldFetchNextPage ? nextPageCursor.value : '/api/v3/search'}`, {
        params: shouldFetchNextPage
          ? undefined
          : {
            q: searchQuery.trim(),
          },
      })

      if (shouldFetchNextPage) {
        // Append results to array
        searchResults.value = searchResults.value?.concat(fetchedResults)
        // Since fetching the next page, no need to update `lastSearchResults`
      } else {
        // Store the results
        searchResults.value = fetchedResults
        // Update last results
        lastSearchResults = searchResults.value
      }

      // Store the results
      searchResultsMeta.value = meta
      // Update last results
      lastSearchResultsMeta = searchResultsMeta.value

      // Always store the next page cursor; reset if it doesn't exist
      nextPageCursor.value = searchResultsMeta.value?.page?.next || ''
    } catch (err: any) {
      console.error('ksearch: error fetching search results', err)

      fetchError.value = err
      // TODO: Should we clear on error if results already exist?
      // Clear results on error
      searchResults.value = []
      searchResultsMeta.value = undefined
      lastSearchResults = []
      // Clear the nextPageCursor on error
      nextPageCursor.value = ''
    } finally {
      isLoadingResults.value = false
      isLoadingNextPageResults.value = false
      lastItemSearched = searchQuery
    }
  }

  /**
   * Clear the search results and query
   */
  const clearSearchResults = (): void => {
    searchQuery.value = ''
    searchResults.value = []
  }

  return {
    isLoadingResults,
    isLoadingNextPageResults,
    hasNextPage,
    searchResults,
    fetchError,
    // Methods
    fetchSearchResults,
    focusListItem,
    getSearchResultComponent,
    provideSearchResult,
    clearSearchResults,
  }
}
