import postcss from 'postcss'
import safeParser from 'postcss-safe-parser'
import postcssNesting from 'postcss-nesting'
import postcssCustomProperties from 'postcss-custom-properties'
import postcssMinify from '@csstools/postcss-minify'
import postCssDiscardComments from 'postcss-discard-comments'

/**
 * Parses portal-defined custom CSS returns sanitized CSS.
 *
 * @param {string} cssInput - The custom CSS input to be parsed.
 * @returns {string} The sanitized CSS.
 */
export default function parseCustomCss(cssInput: string): string {
  try {
    // If no CSS is provided, return an empty string
    if (!cssInput) {
      return ''
    }

    // Decode HTML entities before sanitization
    const sanitizeInput = (input: string = ''): string => {
      // Match variations of <script> and <style> tags, including encoded entities
      return input.replace(
        /(&lt;|<)\s*\/?\s*(script|style)[^>]*\s*(&gt;|>)/gi,
        '',
      )
    }

    const sanitizedCssInput = sanitizeInput(cssInput)

    // Process the CSS with PostCSS to sanitize it
    const { css } = postcss([
      // Suport nested CSS
      postcssNesting(),
      // Preserve CSS custom properties
      postcssCustomProperties({
        preserve: true,
      }),
      // Remove comments
      postCssDiscardComments(),
      // Minify the CSS
      postcssMinify(),
    ]).process(sanitizedCssInput, {
      parser: safeParser,
      from: undefined,
      map: false,
    })

    return css
  } catch (error: any) {
    console.error('Error sanitizing custom CSS:', error.message)
    return ''
  }
}
