<template>
  <div
    v-if="showMenu"
    class="layout-menu-footer-sections"
  >
    <div class="footer-sections-content">
      <div class="logo-container">
        <HeaderLogo />
      </div>
      <PageContainer
        class="footer-sections-container"
        display="grid"
        :gap="`var(--kui-space-100, ${KUI_SPACE_100})`"
        :grid-columns-breakpoints="{
          mobile: 2,
          phablet: 3,
          tablet: footerSectionMenuItems.length > 4 ? 4 : footerSectionMenuItems.length,
          desktop: footerSectionMenuItems.length > 5 ? 5 : footerSectionMenuItems.length,
        }"
      >
        <div
          v-for="(footerSection, idx) of footerSectionMenuItems"
          :key="footerSection.title || `footer-section-${idx}`"
          class="footer-section"
        >
          <div
            v-if="footerSection.title"
            class="footer-section-title"
          >
            {{ footerSection.title }}
          </div>
          <nav v-if="footerSection.items?.length">
            <ul>
              <li
                v-for="link of footerSection.items"
                :key="link.path"
              >
                <NuxtLink
                  :external="link.external"
                  :href="link.path"
                  :target="link.external ? '_blank' : '_self'"
                >
                  {{ link.title }}
                </NuxtLink>
              </li>
            </ul>
          </nav>
        </div>
      </PageContainer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { KUI_SPACE_100 } from '@kong/design-tokens'

const route = useRoute()
const footerSectionMenuItems = useNavMenu<PortalFooterSection>('footer_sections')

// Hide the menu on certain routes or if there are no menu items
const showMenu = computed((): boolean => {
  let validRoute = true
  switch (route.name) {
    case 'login-login_path':
    case 'logout':
    case 'register':
    case 'forgot-password':
    case 'reset-password':
      validRoute = false
      break
    default:
      validRoute = true
      break
  }
  return validRoute && footerSectionMenuItems.value?.length > 0
})
</script>

<style lang="scss" scoped>
.layout-menu-footer-sections {
  background-color: var(--kui-color-background, $kui-color-background);
  width: 100%;

  ul {
    list-style: none;
    margin: var(--kui-space-0, $kui-space-0);
    padding: var(--kui-space-0, $kui-space-0);

    li {
      display: block;
      margin-bottom: var(--kui-space-30, $kui-space-30);
    }

    a {
      color: var(--kui-color-text-neutral, $kui-color-text-neutral);
      display: inline-block;
      font-size: var(--kui-font-size-30, $kui-font-size-30);
      text-decoration: none;
      transition: color 0.2s ease-in-out;
      user-select: none;

      &.router-link-active {
        color: var(--kui-color-text-primary, $kui-color-text-primary);
      }

      &:hover {
        color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);

        &.router-link-active {
          color: var(--kui-color-text-primary, $kui-color-text-primary);
        }
      }

      // Hide the external link icon
      &[target="_blank"] {
        background-image: none !important;
        padding-right: var(--kui-space-0, $kui-space-0);
      }
    }
  }
}

.footer-sections-content {
  display: flex;
  flex-direction: column;
  gap: var(--kui-space-110, $kui-space-110);
  margin: 0 auto;
  max-width: var(--kui-breakpoint-laptop, $kui-breakpoint-laptop);
  padding: var(--kui-space-100, $kui-space-110) var(--kui-space-70, $kui-space-70);
  width: 100%;

  @media (min-width: $kui-breakpoint-mobile) {
    flex-direction: row;
    gap: var(--kui-space-70, $kui-space-70);
  }
}

.logo-container {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  min-width: 240px;
}

.footer-section {
  &-title {
    color: var(--kui-color-text-neutral-stronger, $kui-color-text-neutral-stronger);
    font-size: var(--kui-font-size-30, $kui-font-size-30);
    font-weight: var(--kui-font-weight-semibold, $kui-font-weight-semibold);
    line-height: var(--kui-line-height-30, $kui-line-height-30);
    margin-bottom: var(--kui-space-30, $kui-space-30);
    user-select: none;
  }
}
</style>
