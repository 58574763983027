<template>
  <div
    :id="componentId"
    class="portal-logo"
  >
    <img
      :alt="context?.name"
      preload
      :src="imageSrc"
    >
  </div>
</template>

<script setup lang="ts">
const { context } = storeToRefs(usePortalStore())

const props = defineProps({
  width: {
    type: Number,
    default: 200,
  },
  /** Provide custom CSS rules, scoped to this component */
  styles: {
    type: String,
    required: false,
    default: '',
  },
})
// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => props.styles), useAttrs().id as string)
// We use a dynamic path to the logo asset to prevent Vite from attempting to resolve the path at build time.
// Important: use the proxied path
const imageSrc = '/_proxy/api/v3/assets/logo'
const imageWidth = computed((): string => `${props.width}px`)
</script>

<style lang="scss" scoped>
.portal-logo {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    display: block;
    margin: var(--kui-space-0, $kui-space-0) var(--kui-space-auto, $kui-space-auto);
    max-width: v-bind(imageWidth);
  }
}
</style>
