<template>
  <SearchResultListItem
    v-if="searchResult"
    :disabled="disabled"
    :href="searchResult.url && !searchResult.url.includes('undefined') ? searchResult.url : undefined"
  >
    <template #header>
      <div class="header">
        <component
          :is="getSearchResultIcon(searchResult.type)"
          as="div"
          :color="`var(--kui-color-text-neutral-strong, ${KUI_COLOR_TEXT_NEUTRAL_STRONG})`"
          :size="KUI_ICON_SIZE_30"
          :title="searchResult.type"
        />
        <div
          v-if="searchResult?.header"
          class="result-header"
          data-testid="search-result-header"
        >
          {{ searchResult.header }}
        </div>
      </div>
    </template>
    <template #default>
      <h3
        class="name"
        data-testid="search-result-name"
      >
        {{ searchResult.name || searchResult.id }}
      </h3>
    </template>
    <template
      v-if="!!$slots.default"
      #secondary
    >
      <div class="secondary">
        <slot name="default" />
      </div>
    </template>
    <template
      v-if="searchResult.badge?.text"
      #attributes
    >
      <PortalKBadge
        :appearance="searchResult.badge?.appearance || 'info'"
        class="attributes-badge"
        data-testid="search-result-badge"
        max-width="200px"
        :tooltip="searchResult.badge.text"
        truncation-tooltip
      >
        {{ searchResult.badge.text }}
      </PortalKBadge>
    </template>
  </SearchResultListItem>
</template>

<script setup lang="ts">
import { KUI_ICON_SIZE_30, KUI_COLOR_TEXT_NEUTRAL_STRONG } from '@kong/design-tokens'
import { inject } from 'vue'

const searchResult = inject<InjectedSearchResult | undefined>(SearchResultInjectionKey, undefined)

defineProps({
  /** The string to use as the result `name` if you want something different than `searchResult.name` */
  name: {
    type: String,
    required: false,
    default: '',
  },
  /** Is the component being utilized as a fallback result item? If yes, pass true. */
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
})
</script>

<style lang="scss" scoped>
// Reusable truncate mixin
@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attributes-badge {
  display: flex;
  flex: 0 0 auto; // Take up as much space as needed, maxing out at the max-width attribute defined on the component
  margin-left: auto;
}

.name {
  color: var(--kui-color-text, $kui-color-text);
  flex: 1 1 auto; // Take up as much space as possible while accounting for the .attributes-badge
  font-family: var(--kui-font-family-text, $kui-font-family-text);
  font-size: var(--kui-font-size-40, $kui-font-size-40);
  font-weight: var(--kui-font-weight-medium, $kui-font-weight-medium);
  line-height: var(--kui-line-height-30, $kui-line-height-30);
  margin: var(--kui-space-0, $kui-space-0);
  @include truncate;
}

.secondary {
  color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
}

.header {
  align-items: center;
  display: flex;
  gap: var(--kui-space-20, $kui-space-20);
}

.result-header {
  color: var(--kui-color-text-neutral-stronger, $kui-color-text-neutral-stronger);
  font-family: var(--kui-font-family-text, $kui-font-family-text);
  font-weight: var(--kui-font-weight-medium, $kui-font-weight-medium);
}
</style>
