import { COOKIE_PREVIEW_MODE, COOKIE_PREVIEW_ID } from '../constants/preview-mode'
import { randomUUID } from 'uncrypto'

export const useSessionStore = defineStore('session', () => {
  // Session data
  const session = ref<Session>({
    authenticated: false, // Initialize as false
    developer: undefined, // Initialize as undefined
  })

  const { cookies: { portalLoginReturnPath }, portal: { origin: localPortalOrigin }, datadog } = useRuntimeConfig().public
  const portalConfigStore = usePortalStore()
  const url = useRequestURL()
  const { $datadogRum } = useNuxtApp()

  /**
   * Initialize DataDog with the user (or anonymous user) data.
   *
   * Determines if the user is authenticated and sets the user data accordingly.
   */
  const setDatadogUser = (): void => {
    // If Datadog is not enabled, exit early
    if (![true, 'true'].includes(datadog.enabled)) {
      return
    }

    const { context } = storeToRefs(portalConfigStore)

    // !Important: Ensure all keys are snake_case
    const userData = {
      id: session.value.developer?.id ? session.value.developer.id : `anonymous-${randomUUID()}`,
      org_id: context.value?.org_id,
      portal_id: context.value?.portal_id,
      // Always fallback to 'stable'
      feature_set: context.value?.feature_set || 'stable',
    }

    // Set the user data
    $datadogRum.setUser(userData)
  }

  // Watch for changes to the authenticated state
  watch(() => session.value.authenticated, async (isAuthenticated) => {
    // If authenticated
    if (isAuthenticated) {
      // Initialize DataDog with the actual user id
      setDatadogUser()
    } else {
      // Clear out the developer data
      session.value.developer = undefined

      // Remove the DataDog user
      $datadogRum.stopSession()
      $datadogRum.clearUser()

      // Initalize Datadog with anonymous user
      setDatadogUser()

      // Clear Nuxt data; wrap in a try/catch block to prevent errors from throwing during reset
      try {
        clearNuxtData()
      } catch {
        // no-op
      }
    }

    // Refresh the portal dynamic data when the authenticated state changes
    await portalConfigStore.refreshPortalDynamicData()
  })

  /**
   * Store the unauthenticated user's desired path to a cookie in order to redirect the user to the path upon successful login.
   *
   * @param {string | null} returnPath - The return path to be set.
   */
  const setLoginReturnPath = (returnPath: string | null): void => {
    try {
      // Check if the Nuxt app context is available; if not, exit early
      if (!tryUseNuxtApp()) {
        return
      }

      // If the return path is not set or is empty, set the cookie to null (must be null, not undefined or empty string)
      let cookieReturnPath = !returnPath || String(returnPath || '').trim() === '' ? null : returnPath

      // No need to store certain pages and paths in the cookie
      const ignoredReturnPaths = ['/login', '/logout', '/register', '/forgot-password', '/reset-password', '/_proxy/', '/api/', '/_api/', '/_assets/', '/_nuxt', '/__nuxt', '/sitemap', '__sitemap__']
      // If the desired route is the homepage or in the ignore list, do not store the path and just exit early
      if (returnPath !== null && (returnPath === '/' || ignoredReturnPaths.some(ignoredPath => returnPath?.startsWith(ignoredPath)))) {
        return
      }

      // Remove any preview mode query parameters from the return path
      const [baseReturnPath, queryString] = cookieReturnPath?.split('?') || []
      if (queryString) {
        const searchParams = new URLSearchParams(queryString)
        // Remove undesired query parameters
        for (const param of [COOKIE_PREVIEW_MODE, COOKIE_PREVIEW_ID]) {
          searchParams.delete(param)
        }
        const newQueryString = searchParams.toString()
        cookieReturnPath = newQueryString ? `${baseReturnPath}?${newQueryString}` : baseReturnPath || null
      }

      // Create the cookie
      const returnPathCookie = useCookie(portalLoginReturnPath, {
        path: '/',
        domain: localPortalOrigin || url.hostname === 'localhost' ? undefined : url.hostname, // Scope the cookie to the current subdomain
        maxAge: cookieReturnPath ? 3600 : 0, // Max age of 1 hour if set, otherwise set the Max-Age to 0 to delete the cookie
        sameSite: localPortalOrigin ? false : 'none' as (boolean | 'lax' | 'strict' | 'none' | undefined), // Set SameSite to false on localhost (for Safari cookie compatibility) and to 'lax' otherwise
        httpOnly: false,
        secure: localPortalOrigin ? false : true, // Set Secure to false on localhost (for Safari cookie compatibility)
        partitioned: localPortalOrigin ? false : true, // Set to false on localhost (for Safari cookie compatibility)
      })

      // In case the cookie already exists, we need to explicitly set the value
      returnPathCookie.value = cookieReturnPath
    } catch {
      // no-op: Do not throw an error if the cookie cannot be set
    }
  }

  /**
   * Retrieves the login return path from the cookie.
   * @returns {string | null} The login return path if available, otherwise null.
   */
  const getLoginReturnPath = (): string | null => {
    try {
      return useCookie(portalLoginReturnPath).value || null
    } catch {
      return null
    }
  }

  return {
    session,
    // Methods
    getLoginReturnPath,
    setLoginReturnPath,
    setDatadogUser,
  }
})
