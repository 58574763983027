<template>
  <div class="search-results">
    <div
      v-if="fetchError"
      data-testid="search-results-fetch-error"
    >
      <PortalKAlert
        v-if="fetchErrorMessage"
        appearance="danger"
        class="query-error"
        data-testid="query-error"
      >
        <template #default>
          {{ fetchErrorMessage }}
        </template>
      </PortalKAlert>
    </div>

    <div v-else-if="searchResults?.length || isLoadingResults || isLoadingNextPageResults">
      <ul
        class="search-result-list"
        data-testid="search-results-list"
      >
        <template v-if="isLoadingResults && !isLoadingNextPageResults">
          <!-- Loading -->
          <li class="screen-reader-only">
            {{ t('search.loading') }}
          </li>
          <SearchResultListItem
            v-for="n of 4"
            :key="`result-skeleton-${n}`"
            aria-hidden="true"
            loading
          />
        </template>
        <template v-else>
          <p class="search-results-title">
            {{ t('search.results') }}
          </p>
          <!-- Results -->
          <component
            :is="getSearchResultComponent(result.type)"
            v-for="result in (searchResults || [])"
            :key="result.id"
            :result="result"
            @keydown.down.prevent="($event: KeyboardEvent) => focusListItem('down', $event)"
            @keydown.up.prevent="($event: KeyboardEvent) => focusListItem('up', $event)"
          />
        </template>
      </ul>
      <div
        v-if="searchResults?.length && hasNextPage"
        :id="PORTAL_SEARCH_INFINITE_SCROLL_ELEMENT_ID"
        ref="infiniteScrollTrigger"
        class="infinite-scroll-trigger"
      >
        <PortalKButton
          appearance="tertiary"
          :disabled="isLoadingResults || isLoadingNextPageResults"
          type="button"
          @click="fetchNextPage"
        >
          <ProgressIcon
            v-if="isLoadingResults || isLoadingNextPageResults"
            :size="KUI_ICON_SIZE_80"
            :title="t('search.loading')"
          />
          <span>{{ isLoadingResults || isLoadingNextPageResults ? t('search.loading') : t('actions.load_more_results') }}</span>
        </PortalKButton>
      </div>
    </div>
    <div v-else-if="!!searchQuery && !searchResults?.length && !isLoadingResults && !isLoadingNextPageResults">
      <SearchResultsNoContent
        :description="t('search.no_results.description')"
        :title="t('search.no_results.title')"
      />
    </div>
    <div v-else-if="true">
      <SearchResultsNoContent
        :description="t('search.instructions.description')"
        :title="t('search.instructions.title')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProgressIcon } from '@kong/icons'
import { KUI_ICON_SIZE_80 } from '@kong/design-tokens'
import { useElementVisibility } from '@vueuse/core'

const emit = defineEmits<{
  (e: 'loading', isLoading: boolean): void
  (e: 'result-count', count: number): void
}>()

const { isLoadingResults, isLoadingNextPageResults, fetchError, fetchSearchResults, getSearchResultComponent, focusListItem, hasNextPage, searchResults } = useSearch()
const { t } = useI18n()
const searchQuery = useState(PORTAL_SEARCH_QUERY_KEY, () => '')

// Search is debounced in `SearchModalInput.client.vue`
watch(searchQuery, async (query: string) => await fetchSearchResults(query))

// Emit the loading state
watch(isLoadingResults, async (loading: boolean) => {
  // We don't want to collapse the results when loading the next page
  // so check that we're not fetching the next page
  if (!isLoadingNextPageResults.value) {
    emit('loading', loading)
  }

  // If there are results, focus on the first one
  if (!loading && searchResults.value?.length) {
    await nextTick()

    const firstResult: HTMLLIElement | null = document?.querySelector('ul.search-result-list li:first-of-type > a:not(.disabled)')
    if (firstResult) {
      firstResult.focus()
    }
  }
})

watch(searchResults, (results: SearchResultItem[] | undefined) => {
  emit('result-count', results?.length || 0)
})

const fetchErrorMessage = useState<string>('search-fetch-error-message', () => '')
watch(fetchError, (err: any) => {
  // Reset the local error message
  fetchErrorMessage.value = ''
  // TODO: handle 401 for refresh, and more error cases here
  if (err?.response?.data?.invalid_parameters?.length && err?.response?.status === 400) {
    // this is to show server side error of parsing query as if it would be client side error.
    // eg: type:xxxx -> returns xxxx not valid type
  } else {
    fetchErrorMessage.value = err?.message || err?.response?.data?.detail || t('errors.unexpected')
  }
})

const infiniteScrollTrigger = useTemplateRef('infiniteScrollTrigger')
const infiniteScrollTriggerIsVisible = useElementVisibility(infiniteScrollTrigger)
// If not already fetching and no errors, fetch the next page
const fetchNextPage = async (): Promise<void> => {
  if (!searchResults.value?.length || isLoadingResults.value || isLoadingNextPageResults.value || !hasNextPage.value || fetchError.value) {
    return
  }
  await fetchSearchResults(searchQuery.value, true)
}

// If the load more button is visible and not already fetching, increment the page
watch(infiniteScrollTriggerIsVisible, async (visible: boolean) => {
  if (visible) {
    await fetchNextPage()
  }
})

onMounted(async () => {
  // Only fetch results on mount if there is a search query
  if (searchQuery.value) {
    await fetchSearchResults(searchQuery.value)
  }
})
</script>

<style lang="scss" scoped>
.search-results {
  display: flex;
  position: relative;
  width: 100%;

  > div {
    width: 100%;
  }
}

.search-result-list {
  list-style: none;
  margin: var(--kui-space-0, $kui-space-0);
  padding: var(--kui-space-20, $kui-space-20);
}

.search-results-title {
  color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
  font-size: var(--kui-font-size-30, $kui-font-size-30);
  font-weight: var(--kui-font-weight-semibold, $kui-font-weight-semibold);
  margin-bottom: var(--kui-space-40, $kui-space-40);
}

.infinite-scroll-trigger {
  align-items: center;
  color: var(--kui-color-text-neutral-weak, $kui-color-text-neutral-weak);
  display: flex;
  justify-content: center;
  padding-bottom: var(--kui-space-80, $kui-space-80);
}

.screen-reader-only {
  border-width: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: var(--kui-space-0, $kui-space-0);
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.query-error {
  align-items: center;
}
</style>
