<template>
  <component
    :is="getMdcTag(tag)"
    :id="componentId"
    :class="['page-container', displayClass]"
    data-testid="page-container"
  >
    <MDCSlot name="default" />
  </component>
</template>

<script setup lang="ts">
import { KUI_SPACE_0, KUI_SPACE_70, KUI_BORDER_RADIUS_0 } from '@kong/design-tokens'
import { kebabCase } from 'scule'
import type { BackgroundImage, PageContainerProps } from '#imports'

const {
  alignItems = 'initial',
  background = 'initial',
  backgroundColor = 'initial',
  backgroundImage = {
    url: 'initial',
    backgroundOverlay: null,
    backgroundOrigin: 'border-box',
    backgroundPosition: 'initial',
    backgroundRepeat: 'initial',
    backgroundSize: 'auto',
  },
  border = 'initial',
  borderRadius = `var(--kui-border-radius-0, ${KUI_BORDER_RADIUS_0})`,
  color = 'initial',
  display = null,
  flex = 'initial', // sets initial value as '0 1 auto'
  flexDirection = 'row',
  flexWrap = 'nowrap',
  gap = `var(--kui-space-70, ${KUI_SPACE_70})`,
  gridColumns = 1,
  gridColumnsBreakpoints = {},
  justifyContent = 'initial',
  margin = `var(--kui-space-0, ${KUI_SPACE_0})`,
  maxWidth = '100%',
  padding = `var(--kui-space-0, ${KUI_SPACE_0})`,
  tag = 'div',
  textAlign = 'inherit',
  width = '100%',
  styles = '',
} = defineProps<PageContainerProps>()

interface MappedBackgroundImage extends BackgroundImage {
  background?: string | null
}

// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), useAttrs().id as string)

const computedDisplay = computed((): string => {
  if (display) {
    return display
  }

  if (!display && [...mdcInlineTags].includes(tag)) {
    return 'inline'
  }

  // Always fallback to 'block'
  return 'block'
})

const displayClass = computed((): string => {
  switch (computedDisplay.value) {
    case 'block':
    case 'contents':
    case 'flex':
    case 'grid':
    case 'inline-block':
    case 'inline-flex':
    case 'inline-grid':
    case 'inline':
    case 'none':
    case 'table':
    case 'table-row':
    case 'list-item':
    case 'inherit':
    case 'initial':
    case 'unset':
      return `display-${computedDisplay.value}`
    default:
      return `display-${kebabCase(computedDisplay.value)}`
  }
})

const backgroundImageMapping = computed((): Omit<MappedBackgroundImage, 'url' | 'backgroundOverlay'> => {
  // Since we're binding directly to `style`, we need to convert potential nested
  // properties that may be defined as kebab-case in MDC to camelCase in order
  // to properly bind them to the style attribute.
  const bgImage = convertKebabCasePropertiesToCamelCase(backgroundImage)
  // Determine if the bgImage.url prop is null or undefined
  const bgImageUrl = [undefined, null, 'undefined', 'null', 'initial', 'none'].includes(bgImage?.url) ? undefined : bgImage?.url

  const backgroundOptions: Omit<MappedBackgroundImage, 'url' | 'backgroundOverlay'> = {
    backgroundOrigin: bgImage?.backgroundOrigin || 'border-box',
    backgroundPosition: bgImage?.backgroundPosition || 'center',
    backgroundRepeat: bgImage?.backgroundRepeat || 'no-repeat',
    backgroundSize: bgImage?.backgroundSize || 'auto',
  }

  if (bgImage && bgImage?.backgroundOverlay) {
    // Add background key in case if backgroundOverlay is set
    return {
      background: `linear-gradient(${bgImage.backgroundOverlay}, ${bgImage.backgroundOverlay})${bgImageUrl ? `, url('${bgImageUrl}')` : ''}${(backgroundColor && backgroundColor !== 'initial') ? `, ${backgroundColor}` : ''}`,
      ...backgroundOptions,
    }
  } else if (bgImage) {
    // Add backgroundImage key if there's no backgroundOverlay
    return {
      background: bgImageUrl ? `url('${bgImageUrl}')${(backgroundColor && backgroundColor !== 'initial') ? `, ${backgroundColor}` : ''}` : 'initial',
      ...backgroundOptions,
    }
  }

  // Return null values if no backgroundImage is set so the properties are not bound to the component
  return {
    background: null,
    // @ts-ignore - Allow null value so property is not bound
    backgroundOrigin: null,
    // @ts-ignore - Allow null value so property is not bound
    backgroundPosition: null,
    // @ts-ignore - Allow null value so property is not bound
    backgroundRepeat: null,
    // @ts-ignore - Allow null value so property is not bound
    backgroundSize: null,
  }
})

const computedBackground = computed((): string | null => {
  if (backgroundImageMapping.value.background && backgroundImageMapping.value.background !== 'initial') {
    return backgroundImageMapping.value.background
  } else if (background && background !== 'initial') {
    // Background always takes precedence over backgroundColor
    return background
  } else if (backgroundColor && backgroundColor !== 'initial') {
    // if only backgroundColor is set, return it
    return backgroundColor
  }

  // Always fallback to background prop
  return background
})

// Map common values to their flex value
const mappedAlignItems = computed((): string | null => {
  switch (alignItems) {
    case 'top':
    case 'left':
      return 'start'
    case 'bottom':
    case 'right':
      return 'end'
    default:
      return alignItems
  }
})

// Map common values to their flex value
const mappedJustifyContent = computed((): string | null => {
  switch (justifyContent) {
    case 'top':
    case 'left':
      return 'start'
    case 'bottom':
    case 'right':
      return 'end'
    default:
      return justifyContent
  }
})

// Grid Container columns
// !Important: minimum 1 column
const defaultColumnsNumber = computed(() => Number(gridColumns) > 0 ? Number(gridColumns) : 1)
// !Important: Must optionally chain to prevent errors if the value is not set
const mobileColumnsNumber = computed((): number => gridColumnsBreakpoints?.mobile && Number(gridColumnsBreakpoints?.mobile || 0) > 0 ? Number(gridColumnsBreakpoints.mobile) : (defaultColumnsNumber.value < 2 ? defaultColumnsNumber.value + 1 : defaultColumnsNumber.value))
const phabletColumnsNumber = computed(() => gridColumnsBreakpoints?.phablet && Number(gridColumnsBreakpoints?.phablet || 0) > 0 ? Number(gridColumnsBreakpoints.phablet) : (mobileColumnsNumber.value < 3 ? mobileColumnsNumber.value + 1 : mobileColumnsNumber.value))
// Fallback to phablet for larger breakpoints if not set
const tabletColumnsNumber = computed(() => gridColumnsBreakpoints?.tablet && Number(gridColumnsBreakpoints?.tablet || 0) > 0 ? Number(gridColumnsBreakpoints.tablet) : phabletColumnsNumber.value)
const laptopColumnsNumber = computed(() => gridColumnsBreakpoints?.laptop && Number(gridColumnsBreakpoints?.laptop || 0) > 0 ? Number(gridColumnsBreakpoints.laptop) : tabletColumnsNumber.value)
const desktopColumnsNumber = computed(() => gridColumnsBreakpoints?.desktop && Number(gridColumnsBreakpoints?.desktop || 0) > 0 ? Number(gridColumnsBreakpoints.desktop) : laptopColumnsNumber.value)
</script>

<style lang="scss" scoped>
.page-container {
  background: v-bind('computedBackground');
  background-origin: v-bind('backgroundImageMapping.backgroundOrigin');
  background-position: v-bind('backgroundImageMapping.backgroundPosition');
  background-repeat: v-bind('backgroundImageMapping.backgroundRepeat');
  background-size: v-bind('backgroundImageMapping.backgroundSize');
  border: v-bind('border');
  border-radius: v-bind('borderRadius');
  box-sizing: border-box;
  color: v-bind('color');
  display: v-bind('computedDisplay');
  flex: v-bind('flex');
  margin: v-bind('margin');
  max-width: v-bind('maxWidth');
  padding: v-bind('padding');
  text-align: v-bind('textAlign');
  width: 100%;

  @media (min-width: $kui-breakpoint-phablet) {
    width: v-bind('width');
  }

  // Flex and Grid containers
  &.display-flex,
  &.display-grid,
  &.display-inline-flex,
  &.display-inline-grid {
    align-items: v-bind('mappedAlignItems');
    gap: v-bind('gap');
    justify-content: v-bind('mappedJustifyContent');
  }

  // Flex containers only
  &.display-flex,
  &.display-inline-flex {
    flex-direction: v-bind('flexDirection');
    flex-wrap: wrap;

    @media (min-width: $kui-breakpoint-phablet) {
      flex-wrap: v-bind('flexWrap');
    }
  }

  // Grid containers only
  &.display-grid,
  &.display-inline-grid {
    grid-gap: v-bind('gap');
    grid-template-columns: repeat(v-bind('defaultColumnsNumber'), 1fr);

    @media (min-width: $kui-breakpoint-mobile) {
      grid-template-columns: repeat(v-bind('mobileColumnsNumber'), 1fr);
    }

    @media (min-width: $kui-breakpoint-phablet) {
      grid-template-columns: repeat(v-bind('phabletColumnsNumber'), 1fr);
    }

    @media (min-width: $kui-breakpoint-tablet) {
      grid-template-columns: repeat(v-bind('tabletColumnsNumber'), 1fr);
    }

    @media (min-width: $kui-breakpoint-laptop) {
      grid-template-columns: repeat(v-bind('laptopColumnsNumber'), 1fr);
    }

    @media (min-width: $kui-breakpoint-desktop) {
      grid-template-columns: repeat(v-bind('desktopColumnsNumber'), 1fr);
    }
  }
}
</style>
