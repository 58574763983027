/**
 * Truncates a string to a specified length and adds an ellipsis if necessary.
 *
 * @param {string} s The string to truncate.
 * @param {number} allowedLength The maximum length of the truncated string. Default is 200.
 * @returns {string} The truncated string.
 * @example const description = truncateString('This is a long description that needs to be truncated.', 10)
 */
export const truncateString = (s: string, allowedLength: number = 200): string => {
  if (!s) {
    return ''
  }

  const allowedLengthWithEllipsis = allowedLength - 3

  return s.length > allowedLengthWithEllipsis ? `${s.slice(0, allowedLengthWithEllipsis)}...` : s
}

/**
 * Checks if a string is a valid UUID.
 * @param {string} uuid - The string to be checked.
 * @returns {boolean} A boolean indicating whether the string is a valid UUID.
 */
export const isValidUUID = (uuid: string): boolean => {
  if (!uuid) {
    return false
  }

  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i

  return uuidRegex.test(uuid)
}


/**
 * Sanitizes the input string by removing specified HTML tags.
 *
 * @param {string} [input=''] - The input string to be sanitized.
 * @param {string[]} [tags=['script', 'style']] - An array of tag names to be removed from the input string.
 * @returns {string} - The sanitized string with specified tags removed.
 */
export const removeHtmlTags = (input: string = '', tags = ['script', 'style']): string => {
  // Create a regex pattern dynamically based on the 'tags' parameter
  const tagPattern = [...tags].join('|')

  // Match and replace variations of the specified tags, including encoded entities
  return input.replace(
    new RegExp(`(&lt;|<)\\s*/?\\s*(${tagPattern})[^>]*\\s*(&gt;|>)`, 'gi'),
    '',
  )
}
