<template>
  <KBadge v-bind="computedProps">
    <template #icon>
      <MDCSlot
        name="icon"
        unwrap="p"
      />
    </template>

    <template #default>
      <MDCSlot
        name="default"
        unwrap="p"
      />
    </template>
  </KBadge>
</template>

<script setup lang="ts">
import type { PortalKBadgeProps } from '#imports'
import { type BadgeAppearance, BadgeAppearances } from '@kong/kongponents'

const {
  appearance = 'info',
  tooltip = '',
  truncationTooltip = false,
  iconBefore = true,
  maxWidth = '200px',
  styles = '',
} = defineProps<PortalKBadgeProps>()

const attrs = useAttrs()
// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), attrs.id as string)

const computedAppearance = computed((): BadgeAppearance => Object.values(BadgeAppearances).includes(appearance) ? appearance : 'info')

const computedProps = computed(() => ({
  ...attrs,
  id: componentId,
  appearance: computedAppearance.value,
  class: 'portal-k-badge',
  'data-testid': 'data-testid' in attrs ? attrs['data-testid'] : 'portal-k-badge',
  tooltip,
  truncationTooltip,
  maxWidth,
  iconBefore,
}))
</script>
