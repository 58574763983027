import validate from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45preview_45mode_45global from "/work/portal/portal/layers/core/app/middleware/01.preview-mode.global.ts";
import _02_45auth_45global from "/work/portal/portal/layers/core/app/middleware/02.auth.global.ts";
import manifest_45route_45rule from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45preview_45mode_45global,
  _02_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "app-credentials": () => import("/work/portal/portal/layers/core/app/middleware/app-credentials.ts"),
  documents: () => import("/work/portal/portal/layers/core/app/middleware/documents.ts"),
  login: () => import("/work/portal/portal/layers/core/app/middleware/login.ts"),
  "remove-page-trailing-slash": () => import("/work/portal/portal/layers/core/app/middleware/remove-page-trailing-slash.ts")
}