<template>
  <div class="layout-menu-mobile">
    <button
      ref="mobile-menu-toggle"
      :aria-label="t('actions.toggle_main_menu')"
      class="mobile-menu-button"
      :class="{ 'active': mobileMainMenuIsVisible }"
      data-testid="mobile-menu-button"
      type="button"
      @click="mobileMainMenuIsVisible = !mobileMainMenuIsVisible"
    >
      <span class="line" />
      <span class="line" />
      <span class="line" />
    </button>

    <ClientOnly>
      <Teleport to="#teleports">
        <LayoutSidebar
          ref="mobile-menu-sidebar"
          align="right"
          class="mobile-menu-sidebar"
          :class="{ 'open': mobileMainMenuIsVisible }"
          mobile-full-width
        >
          <LayoutMenuHeader mobile-menu />
        </LayoutSidebar>
      </Teleport>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

const { mobileMainMenuIsVisible } = storeToRefs(useLayoutStore())
const { t } = useI18n()
const mobileMenuSidebar = useTemplateRef('mobile-menu-sidebar')
const mobileMenuToggle = useTemplateRef<HTMLButtonElement>('mobile-menu-toggle')
onClickOutside(mobileMenuSidebar, () => {
  if (mobileMainMenuIsVisible.value) {
    mobileMainMenuIsVisible.value = false
  }
}, {
  ignore: [mobileMenuToggle],
})
</script>

<style lang="scss" scoped>
.layout-menu-mobile,
.mobile-menu-sidebar {
  display: flex;

  @media (min-width: $kui-breakpoint-tablet) {
    display: none;
  }
}

.mobile-menu-sidebar {
  background-color: var(--kui-color-background, $kui-color-background);
  // Enforce full height
  height: calc(100% - $header-height) !important;
  top: $header-height !important;
  z-index: 1200; // Keep atop the other Slideouts that are `1099`
}

.mobile-menu-button {
  background-color: var(--kui-color-background-transparent, $kui-color-background-transparent);
  border: none;
  border-radius: var(--kui-border-radius-30, $kui-border-radius-30);
  cursor: pointer;
  justify-content: center;
  outline: none;
  padding: var(--kui-space-0, $kui-space-0) var(--kui-space-20, $kui-space-20);
  position: relative;
  transition: all 0.3s ease-in-out;

  &:focus-visible {
    background-color: var(--kui-color-background-primary-weakest, $kui-color-background-primary-weakest);
    box-shadow: var(--kui-shadow-focus, $kui-shadow-focus);
  }

  .line {
    background-color: var(--kui-color-background-primary, $kui-color-background-primary);
    border-radius: var(--kui-border-radius-30, $kui-border-radius-30);
    display: block;
    height: 2px;
    margin: var(--kui-space-30, $kui-space-30) var(--kui-space-auto, $kui-space-auto);
    transition: all 0.3s ease-in-out;
    width: 24px;
  }

  &.active {
    .line {
      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
      }

      &:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }
}
</style>
