<template>
  <PageContainer
    :id="componentId"
    :background-color="backgroundColor"
    :border="border"
    :border-radius="borderRadius"
    class="portal-card"
    :color="color"
    :data-testid="dataTestId"
    display="flex"
    flex-direction="column"
    :margin="margin"
    :max-width="maxWidth"
    :padding="padding"
    :text-align="textAlign"
    :width="width"
  >
    <div v-if="!!slots.tagline">
      <MDCSlot
        name="tagline"
        unwrap="p"
      />
    </div>
    <div
      v-if="!!slots.title || title || !!slots.actions"
      class="portal-card-header"
    >
      <component
        :is="truncateTitle ? 'TruncateContent' : 'div'"
        class="portal-card-title"
        :lines="2"
      >
        <MDCSlot
          name="title"
          unwrap="p"
        >
          <component :is="getMdcTag(titleTag)">
            {{ title }}
          </component>
        </MDCSlot>
      </component>

      <div v-if="!!slots.actions">
        <MDCSlot
          name="actions"
          unwrap="p"
        />
      </div>
    </div>

    <div class="portal-card-description">
      <MDCSlot
        name="default"
        unwrap="p"
      />
    </div>

    <div v-if="!!slots.footer">
      <MDCSlot
        name="footer"
        unwrap="p"
      />
    </div>
  </PageContainer>
</template>

<script setup lang="ts">
import {
  KUI_BORDER_RADIUS_50,
  KUI_BORDER_WIDTH_10,
  KUI_COLOR_BORDER,
  KUI_SPACE_0,
  KUI_SPACE_90,
} from '@kong/design-tokens'
import type { PortalCardsProps } from '#imports'

const {
  backgroundColor = 'var(--kui-color-background, initial)',
  border = `var(--kui-border-width-10, ${KUI_BORDER_WIDTH_10}) solid var(--kui-color-border, ${KUI_COLOR_BORDER})`,
  borderRadius = `var(--kui-border-radius-50, ${KUI_BORDER_RADIUS_50})`,
  color = 'var(--kui-color-text, initial)',
  margin = `var(--kui-space-0, ${KUI_SPACE_0})`,
  maxWidth = 'auto',
  padding = `var(--kui-space-90, ${KUI_SPACE_90})`,
  width = '100%',
  title = '',
  titleTag = 'h2',
  textAlign = 'left',
  truncateTitle = true,
  styles = '',
} = defineProps<PortalCardsProps>()

const attrs = useAttrs()
// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), attrs.id as string)
const dataTestId = computed((): string => 'data-testid' in attrs ? attrs['data-testid'] as string : 'portal-card')

const slots = defineSlots<{
  tagline?(): any
  title?(): any
  actions?(): any
  default(): any
  footer?(): any
}>()
</script>

<style scoped lang="scss">
.portal-card {
  color: v-bind('color');
  min-height: unset;

  &-header {
    align-items: center;
    display: flex;
    gap: var(--kui-space-40, $kui-space-40);
    justify-content: space-between;
  }

  :deep(.portal-truncate) {
    padding: var(--kui-space-0, $kui-space-0);
  }

  &-title {
    color: v-bind('color');

    &,
    :deep(h1),
    :deep(h2),
    :deep(h3),
    :deep(h4),
    :deep(h5),
    :deep(h6) {
      font-family: var(--kui-font-family-heading, $kui-font-family-heading);
      font-size: var(--kui-font-size-60, $kui-font-size-60);
      line-height: var(--kui-line-height-50, $kui-line-height-50);
      margin-bottom: var(--kui-space-0, $kui-space-0);
      margin-top: var(--kui-space-0, $kui-space-0);
      padding: var(--kui-space-0, $kui-space-0);
    }

    & > * {
      margin-bottom: var(--kui-space-0, $kui-space-0);
    }

    :deep(.portal-truncate-container) {
      > * {
        margin-bottom: var(--kui-space-0, $kui-space-0);
      }
    }

    & * {
      color: v-bind('color');
    }
  }

  &-description {
    font-size: var(--kui-font-size-30, $kui-font-size-30);
    line-height: var(--kui-line-height-30, $kui-line-height-30);
  }
}
</style>
