<template>
  <PageContainer
    :id="componentId"
    :align-items="verticalAlign"
    :background="fullWidth ? background : undefined"
    :background-color="fullWidth && !!backgroundColor ? backgroundColor : undefined"
    :background-image="fullWidth ? backgroundImage : undefined"
    :border="fullWidth && !!border ? border : undefined"
    :border-radius="fullWidth ? borderRadius : undefined"
    class="page-section"
    :class="[{ 'full-width': fullWidth }]"
    data-testid="page-section"
    display="flex"
    flex-direction="row"
    :margin="margin"
    :padding="padding"
    :tag="tag"
  >
    <PageContainer
      :background="!fullWidth ? background : undefined"
      :background-color="!fullWidth && !!backgroundColor ? backgroundColor : undefined"
      :background-image="!fullWidth ? backgroundImage : undefined"
      :border="!fullWidth && !!border ? border : undefined"
      :border-radius="fullWidth ? undefined : borderRadius"
      class="section-container"
      data-testid="section-container"
      :margin="`${KUI_SPACE_0} ${KUI_SPACE_AUTO}`"
      :max-width="maxWidth"
    >
      <PageContainer
        :align-items="!!slots.title ? horizontalAlign : undefined"
        class="section-content"
        :color="color || undefined"
        data-testid="section-content"
        :display="horizontalAlign ? 'flex' : 'block'"
        :flex-direction="horizontalAlign ? (!!slots.title ? 'column' : 'row' ) : undefined"
        :justify-content="!slots.title ? horizontalAlign : undefined"
        :margin="`${KUI_SPACE_0} ${KUI_SPACE_AUTO}`"
        :text-align="textAlign"
      >
        <component
          :is="getMdcTag(titleTag)"
          v-if="!!slots.title"
          class="section-title"
          data-testid="section-title"
          :style="{
            ...titleMapping
          }"
        >
          <MDCSlot
            name="title"
            unwrap="p"
          />
        </component>

        <MDCSlot
          name="default"
        />
      </PageContainer>
    </PageContainer>
  </PageContainer>
</template>

<script setup lang="ts">
import {
  KUI_BREAKPOINT_LAPTOP,
  KUI_FONT_SIZE_70,
  KUI_LINE_HEIGHT_70,
  KUI_SPACE_0,
  KUI_SPACE_70,
  KUI_SPACE_AUTO,
} from '@kong/design-tokens'
import type { PageSectionProps, PageSectionTitle } from '#imports'

const {
  tag = 'section',
  title = undefined,
  fullWidth = false,
  maxWidth = KUI_BREAKPOINT_LAPTOP,
  borderRadius = 'initial',
  margin = `var(--kui-space-0, ${KUI_SPACE_0})`,
  padding = `var(--kui-space-70, ${KUI_SPACE_70})`,
  verticalAlign = 'top',
  horizontalAlign = '',
  color = 'initial',
  background = undefined,
  backgroundColor = 'initial',
  backgroundImage = undefined,
  textAlign = 'left',
  border = 'initial',
  styles = '',
} = defineProps<PageSectionProps>()

const slots = defineSlots<{
  default(): any
  title(): any
}>()

// Inject any custom `styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), useAttrs().id as string)

const titleMapping = computed((): PageSectionTitle => {
  const titleText = convertKebabCasePropertiesToCamelCase(title)
  return {
    fontSize: titleText?.fontSize || `var(--kui-font-size-70, ${KUI_FONT_SIZE_70})`,
    fontWeight: titleText?.fontWeight || '700',
    lineHeight: titleText?.lineHeight || `var(--kui-line-height-70, ${KUI_LINE_HEIGHT_70})`,
    padding: titleText?.padding || undefined,
    margin: titleText?.margin || undefined,
    textAlign: titleText?.textAlign || 'left',
    color: titleText?.color || 'null',
  }
})

const titleTag = computed((): string => title?.tag || 'h2')
</script>

<style lang="scss" scoped>
.page-section {
  flex-grow: 1;

  &.full-width {
    :deep(.page-section.page-hero-section) {
      padding: var(--kui-space-0, $kui-space-0) !important;
    }
  }
}

.section-container {
  // Important: By explicitly setting min-width: 0; on the flex item, we can override the default behavior and allow the element to shrink beyond its automatic minimum size.
  min-width: 0;
}

.section-content {
  flex-grow: 1;
  flex-shrink: 0;

  :deep() {
    h1, h2, h3, h4, h5, h6 {
      // color: inherit;

      > a {
        color: inherit;
      }
    }
  }
}

.section-title {
  width: 100%;
}
</style>
