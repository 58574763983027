/**
 * This plugin is responsible for refreshing the user's session on the server during the initial load of the application.
 *
 * If the portal is not public, the function checks if the refresh cookie is present; if present, the plugin
 * attempts to refresh the session by making a request to the proxied refresh endpoint via $fetchWithCookie.
 * - If the refresh is successful, the session is marked as authenticated.
 * - If the refresh fails, the user's session is destroyed, then marks the session as unauthenticated, and the current route is stored in a cookie to redirect on successful login.
 *
 * If the refresh cookie is not present, the user's session is destroyed in the same way as described above. The session is marked as unauthenticated and the current route is stored in the cookie to redirect on login.
 */
export default defineNuxtPlugin({
  name: 'session-refresh',
  dependsOn: ['datadog', 'app-config', 'fetch-portal-data', 'open-fetch-sdks'],
  async setup() {
    const { context } = storeToRefs(usePortalStore())
    const sessionStore = useSessionStore()
    const { session } = storeToRefs(sessionStore)
    const { portalAccessToken, portalSessionToken } = useRuntimeConfig().public.cookies
    const cookieAccess = useCookie(portalAccessToken)
    const cookieSession = useCookie(portalSessionToken)
    const route = useRoute()
    const event = useRequestEvent()
    const requestURL = useRequestURL()
    const { $fetchWithCookie, $portalApi, $i18n } = useNuxtApp()

    // If Portal authentication is not enabled, no need to attempt to refresh the session
    if (context.value?.authentication_enabled !== true) {
      // Ensure we always initially update the user in Datadog
      sessionStore.setDatadogUser()

      return
    }

    // Since the Portal is not public, if the session environment variables are missing on the server, throw an error
    if (import.meta.server) {
      // If session password is missing
      if (!useRuntimeConfig().portalSessionPassword) {
        throw createError({
          statusCode: 500,
          statusMessage: $i18n.t('errors.env.missing_variable', { variable: 'NUXT_PORTAL_SESSION_PASSWORD' }),
          fatal: true,
        })
      }
    }

    // A local function to log out the user
    const logoutUser = async () => {
      // Important: Destroy the user's session
      try {
        // Log out the user using the method appropriate for the environment
        if (import.meta.server) {
          // Important: Must call a proxied endpoint in order to rewrite cookies
          await $fetchWithCookie(event!, '/api/session/logout', {
            method: 'POST',
            headers: {
              // Add custom Portal Client headers for dynamic request routing
              'x-portal-context-origin': requestURL.origin,
              'x-portal-context-hostname': requestURL.hostname,
            },
          })
        } else {
          // Important: Must call a proxied endpoint in order to rewrite cookies
          await $fetch('/api/session/logout', {
            method: 'POST',
            headers: {
              // Add custom Portal Client headers for dynamic request routing
              'x-portal-context-origin': requestURL.origin,
              'x-portal-context-hostname': requestURL.hostname,
            },
          })
        }
      } catch {
        // no-op
      } finally {
        // Important: Set the authenticated session value to false (this will also refresh the portal config data)
        session.value.authenticated = false
        // Ensure we always initially update the user in Datadog
        sessionStore.setDatadogUser()
      }
    }

    /**
     * A local function to handle the SAML authentication success callback.
     * @returns {boolean} - Returns true if the SAML callback is successful, otherwise false.
     */
    const onSamlAuthenticationSuccess = async (): Promise<boolean> => {
      try {
        // Attempt to retrieve the refresh cookie after a successful SAML login
        // The proxy endpoint should handle storing the refresh token from the set-cookie headers
        await $fetch('/_proxy/api/v3/developer/authenticate/sso', {
          method: 'GET',
          redirect: 'manual', // We don't want to follow any redirects here
          headers: {
            // Add custom Portal Client headers for dynamic request routing
            'x-portal-context-origin': requestURL.origin,
            'x-portal-context-hostname': requestURL.hostname,
          },
        })

        // Return true if the SAML callback is successful
        return true
      } catch {
        // Return false if the SAML callback fails
        return false
      }
    }

    // If portal authentication is enabled and the user is not authenticated
    if (context.value?.authentication_enabled === true && !session.value.authenticated) {

      // !Important: By default, the session token should always be refreshed
      let shouldRefreshToken: boolean = true

      // If the user is returning from SAML authentication flow, skip refreshing the session
      if (context.value?.saml_auth_enabled && route.name === 'login-login_path' && route.query.loginSuccess === 'true' && String(route.query.method || '')?.toLowerCase() === 'saml' && !route.query.refreshed) {
        // Attempt to retrieve the refresh cookie after a successful SAML login
        const samlAuthSuccess = await onSamlAuthenticationSuccess()
        // Do not refresh the session token if the SAML callback was successful
        shouldRefreshToken = samlAuthSuccess !== true

        // Now that SAML was successful, redirect the user to the stored return to path, or the homepage
        await navigateTo({
          name: 'login-login_path',
          // Do not pass `params`, just the query
          query: {
            ...route.query,
            refreshed: 'true',
          },
        }, {
          // The user returned from the SAML auth flow, so set external to `true` to force a full page reload
          external: true,
        })
      }

      // If the session cookie is present, or in the client, and not on the Logout route, attempt to refresh the session
      if ((import.meta.client || cookieSession.value) && !route.path.includes('/logout')) {
        try {
          // If on the server, and there is not an access cookie, and there is a session cookie, attempt to refresh the session
          if (shouldRefreshToken && import.meta.server && !cookieAccess.value && cookieSession.value) {
            await $fetchWithCookie(event!, '/api/session/refresh', {
              method: 'POST',
              headers: {
                // Add custom Portal Client headers for dynamic request routing
                'x-portal-context-origin': requestURL.origin,
                'x-portal-context-hostname': requestURL.hostname,
              },
            })
          }

          // We always allow the /developer/me call below on the client and server to verify
          // that the user has an active session before setting `session.authenticated` to true.
          // This creates a 401 error on unauthenticated pages if the user is missing their token; however,
          // it properly allows the session to be restored if the user has a valid refresh token.

          const unauthenticatedAllowedPaths = ['/login', '/login/sso', '/logout', '/register', '/forgot-password', '/reset-password']
          // If on the server, or on the client and on an unauthenticated path, do not retry the session data request
          const shouldRetryRequest = import.meta.server || (import.meta.client && unauthenticatedAllowedPaths.includes(route.path)) ? false : undefined

          // Retrieve the developer's session data
          const developerData = await $portalApi('/api/v3/developer/me', {
            method: 'GET',
            retry: shouldRetryRequest,
            headers: {
              // Add custom Portal Client headers for dynamic request routing
              'x-portal-context-origin': requestURL.origin,
              'x-portal-context-hostname': requestURL.hostname,
            },
          })
          // Store the developer data in the session store
          session.value.developer = developerData

          // Important: Set the authenticated session value to true (this will also refresh the portal config data)
          session.value.authenticated = true
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error: any) {
          // Log out the user
          await logoutUser()

          // Store the current route in the cookie to redirect on login
          sessionStore.setLoginReturnPath(route.fullPath)
        }
      } else {
        // Log out the user
        await logoutUser()

        // Store the current route in the cookie to redirect on login
        sessionStore.setLoginReturnPath(route.fullPath)
      }
    }
  },
})
