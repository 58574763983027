import { datadogRum } from '@datadog/browser-rum'
import type { RumErrorEvent, RumEvent, RumActionEvent } from '@datadog/browser-rum'

/** Errors to ignore in DataDog */
export const IGNORED_ERRORS = [
  'Network error',
  'Hydration completed but contains mismatches',
  // Add additional errors as needed to filter out of Datadog
]

export default defineNuxtPlugin({
  name: 'datadog',
  setup() {
    const { portal: { origin: localPortalOrigin }, datadog, buildCommitSha } = useRuntimeConfig().public
    const requestURL = useRequestURL()

    const maskEmailAddress = (emailAddress: string): string => {
      try {
        const mask = (str: string) => {
          const strLen = str.length
          if (strLen > 4) {
            return str.substring(0, 1) + str.substring(1, strLen - 1).replace(/\w/g, '*') + str.substring(-1, 1)
          }
          return str.replace(/\w/g, '*')
        }
        return emailAddress.replace(/([\w.]+)@([\w.]+)(\.[\w.]+)/g, (m, p1, p2, p3) => {
          return mask(p1) + '@' + mask(p2) + p3
        })
      } catch (error: any) {
        console.error('datadog: Error masking email address', error)
        return 'user_email_address'
      }
    }

    // If Datadog is enabled via NUXT_PUBLIC_DATADOG_ENABLED, and initialize it
    if ([true, 'true'].includes(datadog.enabled) && !!datadog.applicationId && !!datadog.clientToken) {
      // Initialize Datadog RUM
      datadogRum.init({
        applicationId: datadog.applicationId,
        clientToken: datadog.clientToken,
        site: 'datadoghq.com',
        service: 'portal-nuxt',
        env: datadog.env,
        version: buildCommitSha,
        allowedTracingUrls: [
          // If running locally, add the local portal origin, otherwise utilze the request URL origin
          ...(localPortalOrigin ? [localPortalOrigin, requestURL.origin] : [requestURL.origin, ...(import.meta.client ? [window.location.origin] : [])]),
        ],
        silentMultipleInit: true,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        defaultPrivacyLevel: 'mask-user-input',
        // enablePrivacyForActionName: true, // TODO: Do we want to enable this? https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/privacy_options/#mask-action-names
        actionNameAttribute: 'data-testid',
        trackAnonymousUser: true,
        traceSampleRate: 100,
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        useSecureSessionCookie: true,
        usePartitionedCrossSiteSessionCookie: true,
        enableExperimentalFeatures: [
          'clickmap',
          'feature_flags',
        ],
        beforeSend: (event: RumEvent): boolean => {
          // Do not send events if the portal is running in an iframe
          if (isPortalInIframe()) {
            return false
          }

          // Extract the error message
          const errMessage = (event as RumErrorEvent)?.error?.message || ''
          // Ignore errors that are in the IGNORED_ERRORS list
          if (IGNORED_ERRORS.some((ignoredError: string) => errMessage.toLowerCase().includes(ignoredError.toLowerCase()))) {
            // We don't want to surface this error in datadog, there is no action dev can take to fix it
            return false
          }

          // Mask all email addresses
          if (event.type === 'action' && (event as RumActionEvent).action?.target?.name) {
            // extracting e-mails from event.name
            let maskedName = (event as RumActionEvent).action.target?.name || ''
            const emails = maskedName?.match(/([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/)
            for (const email of emails || []) {
              const emailToReplace = email.replace('"multiselect-item-', '')
              maskedName = maskedName.replace(emailToReplace, maskEmailAddress(emailToReplace))
            }
            // @ts-ignore - none of those is undefined
            event.action.target.name = maskedName
          }

          return true
        },
      })
    }

    /**
     * Time the execution of events for performance monitoring through Datadog RUM.
     *
     * This function allows tracking the duration of specific events by calling it once
     * with 'start' and once with 'end' for the same named event.
     *
     * Add new event names in `layers/core/app/enums/time-event-keys.ts`.
     *
     * @param {Object} options - The event configuration
     * @param {TimeEventKeys} options.name - A unique name for the event
     * @param {string} [options.description] - Optional description for the event
     * @param {Record<string, any>} [options.context] - Optional additional context data for the event
     * @param {'start'|'end'} options.action - Whether to start or end the timing of the event
     *
     * @returns {void}
     */
    const timeEvent = ({ name, description, context, action }: {
      /** A unique name for the event */
      name: TimeEventKeys
      /** A description for the event */
      description?: string
      /** Additional context to capture for the event */
      context?: Record<string, any>
      /** Start or end the timing. */
      action: 'start' | 'end'
    }): void => {
      try {
        // If required params are not set
        if (!name || !action || !['start', 'end'].includes(action)) {
          return
        }
        // If Datadog not initialized
        if (![true, 'true'].includes(datadog.enabled) || typeof datadogRum === 'undefined') {
          return
        }

        const sanitizedName = name
          .toString() // Convert enum value to string
          .toLowerCase() // Convert to lowercase
          .replace(/\s+/g, '-') // Replace spaces with dashes
          .replace(/[^a-z0-9-]/g, '') // Remove non-alphanumeric and non-dash characters
          .replace(/-+/g, '-') // Replace multiple dashes with a single dash

        if (action === 'start') {
          datadogRum.startDurationVital(sanitizedName, { context, description })
        } else {
          datadogRum.stopDurationVital(sanitizedName)
        }
      } catch (error: any) {
        console.error('datadog(time-event): Error timing event', error)
      }
    }

    return {
      provide: {
        datadogRum,
        timeEvent,
      },
    }
  },
})
