// Launch Darkly Node Server SDK
import { init as server_initialize, basicLogger as server_basicLogger } from '@launchdarkly/node-server-sdk'
import type { LDClient as Server_LDClient, LDContext as Server_LDContext } from '@launchdarkly/node-server-sdk'

// Launch Darkly JavaScript Client SDK
import { initialize as client_initialize, basicLogger as client_basicLogger } from 'launchdarkly-js-client-sdk'
import type { LDClient as Client_LDClient, LDContext as Client_LDContext, LDEvaluationDetail, LDInspection } from 'launchdarkly-js-client-sdk'

/**
 * Initialize Launch Darkly SDKs and identify the user.
 */
export default defineNuxtPlugin({
  name: 'launch-darkly',
  dependsOn: ['datadog', 'fetch-portal-data', 'session-refresh'],
  parallel: true,
  async setup() {
    // Initialize singletons
    let ldClient: Client_LDClient | undefined = undefined
    let ldServerClient: Server_LDClient | undefined = undefined

    const { context } = storeToRefs(usePortalStore())
    const { session } = storeToRefs(useSessionStore())
    const { launchDarkly: { clientSideID }, datadog, buildCommitSha } = useRuntimeConfig().public
    const { serverSdkKey } = import.meta.server ? useRuntimeConfig().launchDarkly : { serverSdkKey: undefined }
    const { $datadogRum } = useNuxtApp()

    /** The Launch Darkly user context */
    const ldContext = computed((): Client_LDContext | Server_LDContext => {
      // Return the authenticated user context
      if (!!session?.value && session.value.authenticated && session.value.developer?.id) {
        return {
          kind: 'user',
          anonymous: false,
          key: session.value.developer.id,
          featureSet: context.value?.feature_set,
          portalId: context.value?.portal_id,
          orgId: context.value?.org_id,
        }
      }

      // Return an unauthenticated user context.
      // Utilize a common, shared anonymous user key so this does not count against MAUs
      // https://docs.launchdarkly.com/home/observability/anonymous-contexts?q=anonymous#tracking-anonymous-users-with-a-shared-key
      return {
        kind: 'user',
        anonymous: true,
        key: 'ANONYMOUS_USER',
        orgId: context.value?.org_id,
        portalId: context.value?.portal_id,
      }
    })

    /**
     * Identifies the user with LaunchDarkly.
     */
    const identifyUser = async (): Promise<void> => {
      try {
        if (import.meta.client && typeof ldClient !== 'undefined') {
          await ldClient?.identify(ldContext.value as Client_LDContext)
        } else {
          await ldServerClient?.identify(ldContext.value as Server_LDContext)
        }
      } catch (error: any) {
        console.error('Failed to identify user', error)
      }
    }

    // Watch for changes to the authenticated state
    watch(() => session.value.authenticated, async () => {
      // Re-identify the user with LaunchDarkly when the authenticated state changes
      await identifyUser()
    })

    const initialize = async () => {
      // If ldClient and ldServerClient are already initialized, exit early
      if (!!ldClient && !!ldServerClient) {
        return
      }

      const application = {
        id: context.value?.portal_id || undefined,
        version: buildCommitSha,
      }

      if (import.meta.client) {
        try {
          // If the client is already initialized or the clientSideID is undefined, exit early
          if (typeof ldClient !== 'undefined' || !clientSideID) {
            return
          }

          const datadogInspector: LDInspection = {
            type: 'flag-used',
            name: 'dd-inspector',
            method: (key: string, detail: LDEvaluationDetail) => {
              $datadogRum.addFeatureFlagEvaluation(key, detail.value)
            },
          }

          // Create the Launch Darkly client
          ldClient = client_initialize(clientSideID, ldContext.value, {
            application,
            // we do not want any info or warnings in the browser console
            logger: client_basicLogger({ level: 'error' }),
            inspectors: [...([true, 'true'].includes(datadog.enabled) ? [datadogInspector] : [])],
          })

          await ldClient?.waitForInitialization(3)
        } catch (error: any) {
          // initialization failed or did not complete before timeout
          console.error('client:launch-darkly:initialization failed', error)
        }
      } else {
        try {
          // If the client is already initialized or the serverSdkKey is undefined, exit early
          if (typeof ldServerClient !== 'undefined' || !serverSdkKey) {
            return
          }

          ldServerClient = server_initialize(serverSdkKey, {
            application,
            // Disable streaming udpates on the server
            stream: false,
            // we do not want any info or warnings in the browser console
            logger: server_basicLogger({ level: 'error' }),
          }).once('ready', () => {
            identifyUser()
          })
        } catch (error: any) {
          // initialization failed or did not complete before timeout
          console.error('server:launch-darkly:initialization failed', error)
        }
      }
    }


    /**
       * Retrieves the value of a feature flag based on if the code is running on
       * the client or server side and retrieves the feature flag value accordingly.
       *
       * @template T - The expected type of the feature flag value.
       * @param {FeatureFlags} flagKey - The unique key of the feature flag.
       * @param {T} defaultValue - The default value of the flag, to be used if the value is not available from LaunchDarkly.
       * @returns {Promise<T>} A promise that resolves to the value of the feature flag.
       *
       * @example const isFeatureEnabled = await getFeatureFlag<boolean>('my-feature-flag', false)
       */
    const getFeatureFlag = async <T extends boolean | number | string | object>(flagKey: FeatureFlags, defaultValue: T): Promise<T> => {
      try {
        if (import.meta.client) {
          // If the client is undefined or the clientSideID is not provided, return the default value
          if (typeof ldClient === 'undefined' || !clientSideID) {
            console.info('launch-darkly: ldClient not initialized. Returning default value.')
            return defaultValue
          }
          return ldClient?.variation(flagKey, defaultValue)
        } else {
          // If the server client is undefined or serverSdkKey is not provided, return the default value
          if (typeof ldServerClient === 'undefined' || !serverSdkKey) {
            console.info('launch-darkly: ldServerClient not initialized. Returning default value.')
            return defaultValue
          }
          // On the server, you must always provide the context
          return await ldServerClient?.variation(flagKey, ldContext.value as Server_LDContext, defaultValue)
        }
      } catch (error: any) {
        console.error(`launch-darkly: Failed to retrieve feature flag: '${flagKey}'`, error)
        return defaultValue
      }
    }

    // Initialize Launch Darkly
    if (import.meta.client) {
      await initialize()
    } else {
      // Do not await this, as it is not critical to the application's functionality when rendering on the server
      initialize()
    }

    return {
      provide: {
        identifyUser,
        getFeatureFlag,
      },
    }
  },
})
