export default defineNuxtPlugin({
  name: 'fetch-portal-data',
  dependsOn: ['datadog', 'app-config'],
  async setup() {
    const portalStore = usePortalStore()
    const { context, customization } = storeToRefs(portalStore)
    const { enabled: previewModeEnabled, state: previewModeState } = usePortalPreviewMode()

    // If on the server (always execute), or if in the client and portal data is not already initialized
    if (import.meta.server || (import.meta.client && context.value?.authentication_enabled === undefined)) {
      // Fetch portal data
      await portalStore.fetchAllPortalData()
    }

    const portalColorMode = computed((): 'light' | 'dark' | 'system' => {
      const defaultThemeMode = 'light'
      const previewModeThemeMode = previewModeState.value?.data?.theme?.mode

      // If preview mode is enabled
      // and the preview mode state has a theme mode
      // and it's a valid mode, use it.
      if (previewModeEnabled.value && !!previewModeThemeMode && ['light', 'dark', 'system'].includes(previewModeThemeMode)) {
        return previewModeThemeMode
      }

      // Otherwise always use the portal's configured mode; fallback to 'light' if not set
      return customization.value?.theme?.mode || defaultThemeMode
    })

    useHeadSafe({
      meta: [
        ...(customization.value?.theme?.colors?.primary ? [
          { name: 'theme-color', content: customization.value.theme.colors.primary },
          { name: 'msapplication-TileColor', content: customization.value.theme.colors.primary },
        ] : []),
      ],
      htmlAttrs: {
        // Add a `data-portal-layout` attribute to the root <html> element
        'data-portal-layout': () => `${customization.value?.layout || DEFAULT_PORTAL_LAYOUT}`,
        // Add a `data-portal-theme` attribute to the root <html> element
        'data-portal-theme': () => `${customization.value?.theme?.name ? customization.value.theme.name : 'ocean'}`,
        // Add a `data-portal-color-mode` attribute to the root <html> element (one of `light`, `dark`, or `system`)
        // Important: The `layers/core/app/plugins/customization.ts` plugin and Shiki also utilize this attribute to determine the theme color mode.
        'data-portal-color-mode': () => portalColorMode.value,
      },
    })
  },
})
