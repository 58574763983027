<template>
  <div class="app-container">
    <NuxtLoadingIndicator :height="2" />
    <NuxtPage />
    <SearchModal v-if="searchModalIsVisible" />
  </div>
</template>

<script setup lang="ts">
//TODO:
// the reason why it's here is SSR execution - this is the only way to make spec-renderer styles to be part of response from the server.
// We might need to give it another try and find a better place ...
import '@kong/spec-renderer/dist/style.css'

const { context } = storeToRefs(usePortalStore())
const { searchModalIsVisible } = storeToRefs(useLayoutStore())

// Upate the title template based on the Portal name
useSeoMeta({
  titleTemplate: () => `%s${ context.value?.name ? ` %separator ${ context.value.name }` : '' }`,
  ogSiteName: () => context.value?.name,
})

useSchemaOrg([
  defineWebSite({
    name: context.value?.name,
  }),
])

defineOgImage({
  component: 'OgImageOcean',
})
</script>

<style lang="scss" scoped>
.app-container {
  &,
  // The <NuxtPage /> component adds a div that must have 100% height as well; don't add a class to the <NuxtPage /> component
  :deep(> div:not(.nuxt-loading-indicator)) {
    height: 100%;
    min-height: 100%; // Important - include min-height for the sticky positioning of elements
  }

  :deep(.nuxt-loading-indicator) {
    background: var(--kui-color-background-primary-weak, $kui-color-background-primary-weak) !important;
  }
}
</style>
