import { TemplateParamsPlugin, AliasSortingPlugin } from '@unhead/vue/plugins'

/**
 * Opt-in to unhead's [Template Params](https://unhead.unjs.io/docs/head/guides/plugins/template-params)
 * and [Alias Tag Sorting](https://unhead.unjs.io/docs/head/guides/plugins/alias-sorting) features.
 */
export default defineNuxtPlugin({
  name: 'unhead',
  async setup() {
    const unhead = injectHead()
    unhead.use(TemplateParamsPlugin)
    unhead.use(AliasSortingPlugin)
  },
})
