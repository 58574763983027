<template>
  <li data-testid="list-item">
    <NuxtLink
      class="list-item-wrapper"
      :class="{ 'loading': loading, 'disabled': disabled }"
      data-testid="search-result"
      :external="!!href && href.startsWith('http')"
      :to="!!href ? { path: href } : '#'"
      @click="handleClick"
    >
      <div
        v-if="loading"
        class="list-item-header"
      >
        <KSkeletonBox
          height="1"
          width="1"
        />
        <KSkeletonBox
          height="1"
          width="5"
        />
      </div>
      <div
        v-else-if="!!$slots.header"
        class="list-item-header"
      >
        <slot name="header" />
      </div>
      <div class="list-item-body">
        <div
          v-if="!!$slots.icon"
          class="list-item-icon"
          data-testid="list-item-icon"
        >
          <slot name="icon" />
        </div>
        <div class="list-item-content">
          <template v-if="loading">
            <KSkeletonBox
              height="2"
              width="50"
            />
            <KSkeletonBox
              height="1"
              width="75"
            />
          </template>
          <template v-else>
            <div class="result-row">
              <slot name="default" />
            </div>
            <div
              v-if="!!$slots.secondary"
              class="result-row secondary-content"
              data-testid="list-item-secondary-content"
            >
              <slot name="secondary" />
            </div>
          </template>
        </div>
        <slot name="attributes" />
      </div>
    </NuxtLink>
  </li>
</template>

<script setup lang="ts">
import { KUI_ICON_SIZE_40 } from '@kong/design-tokens'

const props = defineProps({
  href: {
    type: String,
    required: false,
    default: '',
  },
  /** Is the component being utilized as a loading skeleton */
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  /** Is the component being utilized as a fallback result item? If yes, pass true. */
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const { searchModalIsVisible } = storeToRefs(useLayoutStore())
const { clearSearchResults } = useSearch()
const { datadog } = useRuntimeConfig().public
const { $datadogRum } = useNuxtApp()

const handleClick = (e: MouseEvent) => {
  // If no props.href, do nothing on link click
  if (!props.href || props.href === '#') {
    e.preventDefault()
  } else {
    // Hide search modal
    searchModalIsVisible.value = false
    clearSearchResults()

    // If Datadog is enabled
    if ([true, 'true'].includes(datadog.enabled)) {
      // Trace ksearch invocations
      $datadogRum.addAction('ksearch-goto-result', { url: props.href })
    }
  }
}
</script>

<style lang="scss" scoped>
// Reusable truncate mixin
@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

li {
  display: block;
  margin-bottom: var(--kui-space-20, $kui-space-20);

  &:last-of-type {
    margin-bottom: var(--kui-space-0, $kui-space-0);
  }

  .list-item-wrapper {
    align-items: center;
    background-color: var(--kui-color-background, $kui-color-background);
    border: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
    border-radius: var(--kui-border-radius-30, $kui-border-radius-30);
    box-shadow: none;
    box-sizing: border-box; // do not allow to extend beyond container
    color: var(--kui-color-text-neutral-stronger, $kui-color-text-neutral-stronger);
    display: flex;
    flex-direction: column;
    font-family: var(--kui-font-family-text, $kui-font-family-text);
    font-size: var(--kui-font-size-30, $kui-font-size-30);
    font-weight: var(--kui-font-weight-medium, $kui-font-weight-medium);
    line-height: var(--kui-line-height-30, $kui-line-height-30);
    overflow: hidden;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    user-select: none;

    // Styles for anchor tags
    &:not(.loading):not(.disabled) {

      &:hover {
        border-color: var(--kui-color-border-primary, $kui-color-border-primary);
      }

      // In this case, apply both focus and focus-visible styles since the user navigates on click, meaning the focus state won't be "stuck"
      &:focus,
      &:focus-visible {
        border-color: var(--kui-color-border-primary, $kui-color-border-primary);
        box-shadow: var(--kui-shadow-focus, $kui-shadow-focus);
        outline: none;

        .list-item-header {
          background-color: var(--kui-color-background-primary, $kui-color-background-primary);

          :deep(.header) {
            .result-header, .kui-icon {
              color: var(--kui-color-text-inverse, $kui-color-text-inverse)!important;
              transition: color 0.2s ease-in-out;
            }
          }
        }
      }

      &:active {
        background-color: var(--kui-color-background-primary-weakest, $kui-color-background-primary-weakest);
        border-color: var(--kui-color-border-primary, $kui-color-border-primary);
        box-shadow: var(--kui-shadow-focus, $kui-shadow-focus);
      }
    }

    &.disabled {
      background-color: var(--kui-color-background-disabled, $kui-color-background-disabled);
      border-color: var(--kui-color-border-disabled, $kui-color-border-disabled);
      cursor: not-allowed;

      // In this case, apply both focus and focus-visible styles since the user navigates on click, meaning the focus state won't be "stuck"
      &:focus,
      &:focus-visible {
        box-shadow: var(--kui-shadow-focus, $kui-shadow-focus);
        outline: none;
      }
    }

    // Add a gap between the loading skeleton elements
    &.loading .list-item-content {
      gap: var(--kui-space-40, $kui-space-40);
    }
  }

  .list-item-header,
  .list-item-body {
    display: flex;
    width: 100%;
  }

  .list-item-header {
    align-items: center;
    background-color: var(--kui-color-background-neutral-weakest, $kui-color-background-neutral-weakest);
    border-bottom: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
    color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
    font-size: var(--kui-font-size-20, $kui-font-size-20);
    font-weight: var(--kui-font-weight-regular, $kui-font-weight-regular);
    justify-content: flex-start;
    padding: var(--kui-space-30, $kui-space-30) var(--kui-space-50, $kui-space-50);
    transition: background-color 0.2s ease-in-out;
  }

  .list-item-body {
    gap: var(--kui-space-60, $kui-space-60);
    padding: var(--kui-space-50, $kui-space-50);
  }

  .list-item-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: var(--kui-space-40, $kui-space-40);
    min-height: 40px; // !Important: keep all items the same height, regardless if 1 row or 2
    overflow: hidden;
    width: 100%;
  }

  .list-item-icon {
    align-items: center;
    display: flex;
    flex: 0 0 v-bind('KUI_ICON_SIZE_40'); // Prevent icon resizing and only take up the space it needs
  }

  .result-row {
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-start;

    &.secondary-content {
      :deep() {
        color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
        font-size: var(--kui-font-size-20, $kui-font-size-20);
        font-weight: var(--kui-font-weight-regular, $kui-font-weight-regular);

        // Do not wrap any text in the slotted content, and truncate it if needed
        * {
          @include truncate;
        }

        p {
          margin-bottom: var(--kui-space-40, $kui-space-40);
        }
      }
    }
  }
}
</style>
