/**
 * Determine if the app is running inside an iframe (likely because of previewMode).
 * @returns {boolean}
 */
export const isPortalInIframe = (): boolean => {
  if (!import.meta.client) {
    return false
  }

  return typeof window !== 'undefined' && window.self !== window.top
}
