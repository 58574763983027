<template>
  <KAlert
    :id="componentId"
    :appearance="computedAppearance"
    class="portal-k-alert"
    :data-testid="dataTestId"
    :message="message"
    :show-icon="showIcon"
    :style="computedStyles"
    :title="title"
  >
    <template
      v-if="!!slots.icon"
      #icon
    >
      <MDCSlot
        name="icon"
        unwrap="p"
      />
    </template>
    <template
      v-if="!!slots.default"
      #default
    >
      <MDCSlot
        name="default"
        unwrap="p"
      />
    </template>
  </KAlert>
</template>

<script setup lang="ts">
import type { PortalKAlertProps } from '#imports'
import { AlertAppearances } from '@kong/kongponents'
import type { AlertAppearance } from '@kong/kongponents'
import { KUI_SPACE_0, KUI_SPACE_70 } from '@kong/design-tokens'

const {
  // KAlert props
  appearance = 'info',
  title = '',
  message = '',
  showIcon = false,
  // Shared props
  background = null,
  backgroundColor = null,
  border = null,
  borderRadius = null,
  color = null,
  margin = `var(--kui-space-70, ${KUI_SPACE_70}) var(--kui-space-0, ${KUI_SPACE_0})`,
  padding = null,
  styles = '',
} = defineProps<PortalKAlertProps>()

const attrs = useAttrs()
// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), attrs.id as string)
const dataTestId = computed((): string => 'data-testid' in attrs ? attrs['data-testid'] as string : 'portal-k-alert')

const slots = defineSlots<{
  default?: () => any
  icon?: () => any
}>()

// Compute the appearance and size based on the provided props
const computedAppearance = computed((): AlertAppearance => Object.values(AlertAppearances).includes(appearance) ? appearance : 'info')

const computedStyles = computed(() => ({
  backgroundColor,
  // Background should come _after_ backgroundColor
  background,
  border,
  borderRadius,
  color,
  margin,
  padding,
}))
</script>
