<template>
  <nav
    v-if="mainMenuItems?.length"
    class="layout-menu-header"
    :class="{ 'mobile-menu': mobileMenu }"
  >
    <div class="menu-items">
      <ul>
        <li
          v-for="link of mainMenuItems"
          :key="link.path"
        >
          <NuxtLink
            :class="{ active: checkIfActiveLink(link) }"
            :external="link.external"
            :href="link.path"
            prefetch
            :target="link.external ? '_blank' : '_self'"
          >
            {{ link.title }}
          </NuxtLink>
        </li>
      </ul>
    </div>

    <div
      v-if="context?.authentication_enabled === true"
      class="menu-actions"
    >
      <PortalKButton
        v-if="!session.authenticated && route.name !== 'register'"
        appearance="primary"
        class="sign-up-button"
        :class="{ visible: route.name === 'login-login_path' }"
        data-testid="header-sign-up-button"
        size="large"
        :to="{ name: 'register'}"
      >
        {{ t('actions.sign_up') }}
      </PortalKButton>
      <PortalKButton
        v-if="!session.authenticated && route.name !== 'login-login_path'"
        appearance="tertiary"
        data-testid="header-log-in-button"
        size="large"
        :to="{ name: 'login-login_path'}"
      >
        {{ t('actions.log_in') }}
      </PortalKButton>
      <PortalKButton
        v-if="session.authenticated"
        appearance="tertiary"
        data-testid="header-log-out-button"
        size="large"
        :to="{ name: 'logout'}"
      >
        {{ t('actions.log_out') }}
      </PortalKButton>
    </div>
  </nav>
</template>

<script setup lang="ts">
defineProps({
  mobileMenu: {
    type: Boolean,
    default: false,
  },
})

const { context } = storeToRefs(usePortalStore())
const { session } = storeToRefs(useSessionStore())
const mainMenuItems = useNavMenu<PortalMenuItem>('main')
const route = useRoute()
const { t } = useI18n()

const checkIfActiveLink = (link: PortalMenuItem): boolean => {
  // Special case for the home page
  if (link.path === '/') {
    return route.path === '/'
  } else {
    return !link.external && route.path.startsWith(link.path)
  }
}
</script>

<style lang="scss" scoped>
.layout-menu-header {
  display: flex;
  flex-direction: column;
  height: 100%;

  .menu-items,
  .menu-actions {
    display: flex;
  }

  .menu-items {
    flex-grow: 1;
    overflow-y: auto;
    padding: var(--kui-space-100, $kui-space-100) var(--kui-space-70, $kui-space-70);

    @media (min-width: $kui-breakpoint-mobile) {
      justify-content: center;
    }

    @media (min-width: $kui-breakpoint-tablet) {
      justify-content: flex-start;
      overflow-y: hidden;
    }
  }

  .menu-actions {
    align-items: center;
    border-top: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
    gap: var(--kui-space-70, $kui-space-70);
    justify-content: center;
    padding: var(--kui-space-70, $kui-space-70);

    @media (min-width: $kui-breakpoint-tablet) {
      display: none;
    }
  }

  // Shared styles
  ul {
    display: flex;
    list-style: none;
    margin: var(--kui-space-0, $kui-space-0);
    padding: var(--kui-space-0, $kui-space-0);

    li, a {
      align-items: center;
      display: flex;
      margin-bottom: var(--kui-space-0, $kui-space-0);
    }

    a {
      text-decoration: none;
    }
  }

  // Mobile menu styles
  &.mobile-menu {
    ul {
      flex-direction: column;
      width: 100%;

      li {
        justify-content: flex-start;
        margin-bottom: var(--kui-space-70, $kui-space-70);
        width: 100%;

        @media (min-width: $kui-breakpoint-mobile) {
          justify-content: center;
        }

        // Ensure padding after the last item
        &:last-of-type {
          padding-bottom: var(--kui-space-100, $kui-space-100);
        }
      }

      a {
        color: var(--kui-color-text-neutral-stronger, $kui-color-text-neutral-stronger);
        display: block;
        font-family: var(--kui-font-family-heading, $kui-font-family-heading);
        font-size: var(--kui-font-size-60, $kui-font-size-60);
        font-weight: var(--kui-font-weight-regular, $kui-font-weight-regular);
        line-height: var(--kui-line-height-40, $kui-line-height-40);
        overflow: hidden;
        padding: var(--kui-space-30, $kui-space-30) var(--kui-space-0, $kui-space-0);
        text-decoration: none;
        text-overflow: ellipsis;
        user-select: none;
        white-space: nowrap;
        width: 100%;

        &:hover {
          color: var(--kui-color-text-primary, $kui-color-text-primary);
        }

        &.active,
        &.router-link-active {
          color: var(--kui-color-text-primary, $kui-color-text-primary);
        }

        // Hide the external link icon
        &[target="_blank"] {
          background-image: none !important;
          padding-right: var(--kui-space-0, $kui-space-0);
        }
      }
    }
  }

  // Desktop menu styles
  &:not(.mobile-menu) {
    height: 100%;

    ul {
      align-items: center;
      flex-direction: row;
      gap: var(--kui-space-20, $kui-space-20);
      height: 100%;

      @media (min-width: $kui-breakpoint-tablet) {
        gap: var(--kui-space-70, $kui-space-70);
      }

      li {
        align-items: center;
        height: 100%;
      }

      a {
        color: var(--kui-color-text-neutral-stronger, $kui-color-text-neutral-stronger);
        display: block;
        font-family: var(--kui-font-family-text, $kui-font-family-text);
        font-size: var(--kui-font-size-40, $kui-font-size-40);
        font-weight: var(--kui-font-weight-regular, $kui-font-weight-regular);
        line-height: var(--kui-line-height-40, $kui-line-height-40);
        text-decoration: none;
        transition: color 0.2s ease-in-out;
        user-select: none;
        white-space: nowrap;

        &:hover {
          color: var(--kui-color-text-primary, $kui-color-text-primary);
        }

        &.active,
        &.router-link-active {
          color: var(--kui-color-text-primary, $kui-color-text-primary);
        }

        // Hide the external link icon
        &[target="_blank"] {
          background-image: none !important;
          padding-right: var(--kui-space-0, $kui-space-0);
        }
      }
    }
  }
}
</style>
