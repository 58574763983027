import { COOKIE_PREVIEW_MODE, COOKIE_PREVIEW_ID } from '../constants/preview-mode'
import type { CookieSerializeOptions } from 'cookie-es'

/**
 * Middleware to handle the Preview Mode activation based on the route query parameters
 * Must run before the `auth.global.ts` middleware
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const { origin: localPortalOrigin } = useRuntimeConfig().public.portal
  const url = useRequestURL()

  // TODO: Warning: Cookies with the SameSite=None; Secure that do not also have the Partitioned attribute may be blocked in cross-site contexts on future browser versions. This behavior protects user data from cross-site tracking. See Cookies Having Independent Partitioned State (CHIPS) and Third-party cookies.
  // Source: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie

  // Create a shared cookie options object for the preview mode cookies
  const previewCookieOptions: CookieSerializeOptions = {
    path: '/',
    domain: localPortalOrigin || url.hostname === 'localhost' ? undefined : url.hostname, // Scope the cookie to the current subdomain
    maxAge: 60 * 60, // 1 hour
    sameSite: localPortalOrigin ? false : 'none' as (boolean | 'lax' | 'strict' | 'none' | undefined), // Set SameSite to false on localhost (for Safari cookie compatibility) and to 'none' otherwise for cross-origin,
    httpOnly: false,
    secure: localPortalOrigin ? false : true, // Set Secure to false on localhost (for Safari cookie compatibility)
    partitioned: localPortalOrigin ? false : true, // Set to false on localhost (for Safari cookie compatibility)
  }

  // Initialize preview mode cookies
  const previewCookie = useCookie<string | null>(COOKIE_PREVIEW_MODE, previewCookieOptions)
  const previewIdCookie = useCookie<string | null>(COOKIE_PREVIEW_ID, previewCookieOptions)

  // If the route query params include `preview=false`, force-disable the Preview Mode
  if (String(to.query.preview) === 'false') {
    previewCookie.value = null
    previewIdCookie.value = null

    // Clear the preview mode query params if they exist
    return navigateTo({
      query: {
        // Keep other query params
        ...to.query,
        // Remove preview mode query params
        ...{ preview: undefined, preview_id: undefined },
      },
    })
  }

  // Determine if Preview Mode should be activated based on the route query parameters
  // Requires: preview=true & preview_id=<valid UUID>
  if (!!to.query.preview && String(to.query.preview) === 'true' && !!to.query.preview_id && isValidUUID(String(to.query.preview_id))) {
    /**
     * If the app is running on the server or in an iframe (or opened from new window), set the preview mode cookies
     *
     * TODO: This is a temporary solution to prevent preview mode from being enabled in
     * the wrong context and should be refactored or removed once persistent previews are supported.
     * There is similar logic in `layers/core/plugins/preview-mode.client.ts`.
     */
    if (import.meta.server || (import.meta.client && (window.opener || isPortalInIframe()))) {
      // Set the preview mode cookies
      previewCookie.value = 'true'
      previewIdCookie.value = String(to.query.preview_id)
    }

    // Clear the preview mode query params if they exist
    return navigateTo({
      query: {
        // Keep other query params
        ...to.query,
        // Remove preview mode query params
        ...{ preview: undefined, preview_id: undefined, ref: undefined },
      },
    })
  }
})
