import payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA from "/work/portal/portal/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_0WT2TdLpMOSKjQ3Qic3eV5GjvHTP42_8lBHP9g_j9n4 from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_SDlaCMCLmFMAdeXeg_SIomiiihw_os0uGNbdU1nfDig from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IMXI4Mqs_To7z8UZoVNpqDKjBSc0_yRPV1vnPXdfOwc from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_5_Df5MibenU9q7hOWAlwbNrMpfjqEZ03E35FowsCVs8 from "/work/portal/portal/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_ycV4ROPem1yUgRnPsl7cqjOa392pyHct1n8UsHsBNys from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_7F_aAjWt7fboE9dIv8UKPhNgqUOipTIJ56yf2JXZN8o from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LmQZx5K74xzgeA9U28Ga0YNOg4WldTJ8jDdZ0kO1KsY from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Wt0kgtcAE13_Mgh6rcXdpdbVv4zO17W1r_2zbdRG5Kg from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_zhmpFEMXnfEVQhnKVXEk62GemJGhuiNuSDzIPeBywe4 from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8 from "/work/portal/portal/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/work/portal/portal/apps/portal/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_lZ58S8zxARIMc162_oB93_B9tNUJTeOFjHtvc1CLO54 from "/work/portal/portal/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.22.0_jiti@2.4_0e38a4949389464f7c4d812f579f9f65/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ITWQ3X7GcYHAxm9eIP04JSe5PSDEvWdQbpeyaUmBlfc from "/work/portal/portal/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.14_jiti@2.4.2_sass-embedd_bea10b52decb4d0feff1ab042824795b/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_owkZsZ_YdDVRg8XiWt_HSf_0bxN3Yai3GuF9WU3kHis from "/work/portal/portal/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_xKPtRJ1syCTFMWt5PGtK_1HVQk5slfLp6PO3_mJqjf8 from "/work/portal/portal/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n__fTsup71FdTFb4cpLua5Ol1IRHzh_52g8pDn3kK7CmY from "/work/portal/portal/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import siteConfig_kh2MfVWsGyBiCYRZM0Qr8CUsbY9XJLgG2nmGF5FiMn4 from "/work/portal/portal/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_fGpcM3_SRvhFPRtrVh6lU5b5yQvQWUuMH_dvJJz_TW4 from "/work/portal/portal/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_os4A7WZu71WF8lrm8nVQqXFzC6G6EDncBmmgGWCxRKQ from "/work/portal/portal/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_04EWQETHbX77XJjAM3P2Q0G5oTFiw9Z7I1VLt8jtZfE from "/work/portal/portal/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_vpNs8i0cr9N1X_z0gnT94vc4uwUE_tOHJq1wXR6Srws from "/work/portal/portal/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import kongponents_b1hzSE7jpF4bf1tKNsh3VHi6zP9Nd6vwDbHJM7Y0WhA from "/work/portal/portal/layers/kongponents/app/plugins/kongponents.ts";
import _01_datadog_wvIp4TlSDyFujSJLFXR95Q7MfXC_p8M940mL1_GlLdE from "/work/portal/portal/layers/core/app/plugins/01.datadog.ts";
import _02_app_config_no8eyoDhcmPUAB7sUnrIw8RKL_NZ9Fzz9Y_kZ5ZMA50 from "/work/portal/portal/layers/core/app/plugins/02.app-config.ts";
import _03_fetch_portal_data_H_YEjZjm4AUPVX8tcTmkXqHNB5y_y2HfwChlPFzMvqQ from "/work/portal/portal/layers/core/app/plugins/03.fetch-portal-data.ts";
import _04_session_refresh_Dwid7EotMKTrVM8tp0177ZfB4uAt2jbPm29htQ9qLqs from "/work/portal/portal/layers/core/app/plugins/04.session-refresh.ts";
import custom_scripts_client_pIZ290naDfJUNZUJsKjgqM9ZnAL3xo3XCDBWuMxVvQI from "/work/portal/portal/layers/core/app/plugins/custom-scripts.client.ts";
import customization_D4rIaOwEcdcYiS5vfRBWfZq20PADqfhpb9BYBb7eK64 from "/work/portal/portal/layers/core/app/plugins/customization.ts";
import launch_darkly_hHBgUNAMpfD4YdWrMBWNbOq0c7ByxVhdr_dmWlj0Z6w from "/work/portal/portal/layers/core/app/plugins/launch-darkly.ts";
import open_fetch_sdks_MitLatvCZQc2WDua8MeksLIAD_kHYOyVbbiIMJb42GI from "/work/portal/portal/layers/core/app/plugins/open-fetch-sdks.ts";
import preview_mode_client_Ggr1TCQPc_MSF5nJfnMm_vBIae5Iky0KGDhKudhb5U4 from "/work/portal/portal/layers/core/app/plugins/preview-mode.client.ts";
import unhead_5FH2g9nriaBxpxy5rU5veuYSFDe4pirJ3zF_liE0R8U from "/work/portal/portal/layers/core/app/plugins/unhead.ts";
import ssg_detect_0saNPeVRcHLuEtCbvVG5_13hJmbAyslWDGN_Nj3qASM from "/work/portal/portal/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
import _0_routeRules_vE_1VXroUfmUKH4iu4zzviYcltmxAylDUdjBU2DkTrQ from "/work/portal/portal/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
export default [
  payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA,
  revive_payload_client_0WT2TdLpMOSKjQ3Qic3eV5GjvHTP42_8lBHP9g_j9n4,
  unhead_SDlaCMCLmFMAdeXeg_SIomiiihw_os0uGNbdU1nfDig,
  router_IMXI4Mqs_To7z8UZoVNpqDKjBSc0_yRPV1vnPXdfOwc,
  _0_siteConfig_5_Df5MibenU9q7hOWAlwbNrMpfjqEZ03E35FowsCVs8,
  payload_client_ycV4ROPem1yUgRnPsl7cqjOa392pyHct1n8UsHsBNys,
  navigation_repaint_client_7F_aAjWt7fboE9dIv8UKPhNgqUOipTIJ56yf2JXZN8o,
  check_outdated_build_client_LmQZx5K74xzgeA9U28Ga0YNOg4WldTJ8jDdZ0kO1KsY,
  chunk_reload_client_Wt0kgtcAE13_Mgh6rcXdpdbVv4zO17W1r_2zbdRG5Kg,
  cross_origin_prefetch_client_zhmpFEMXnfEVQhnKVXEk62GemJGhuiNuSDzIPeBywe4,
  plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_lZ58S8zxARIMc162_oB93_B9tNUJTeOFjHtvc1CLO54,
  plugin_ITWQ3X7GcYHAxm9eIP04JSe5PSDEvWdQbpeyaUmBlfc,
  switch_locale_path_ssr_owkZsZ_YdDVRg8XiWt_HSf_0bxN3Yai3GuF9WU3kHis,
  route_locale_detect_xKPtRJ1syCTFMWt5PGtK_1HVQk5slfLp6PO3_mJqjf8,
  i18n__fTsup71FdTFb4cpLua5Ol1IRHzh_52g8pDn3kK7CmY,
  siteConfig_kh2MfVWsGyBiCYRZM0Qr8CUsbY9XJLgG2nmGF5FiMn4,
  inferSeoMetaPlugin_fGpcM3_SRvhFPRtrVh6lU5b5yQvQWUuMH_dvJJz_TW4,
  titles_os4A7WZu71WF8lrm8nVQqXFzC6G6EDncBmmgGWCxRKQ,
  defaultsWaitI18n_04EWQETHbX77XJjAM3P2Q0G5oTFiw9Z7I1VLt8jtZfE,
  i18n_vpNs8i0cr9N1X_z0gnT94vc4uwUE_tOHJq1wXR6Srws,
  kongponents_b1hzSE7jpF4bf1tKNsh3VHi6zP9Nd6vwDbHJM7Y0WhA,
  _01_datadog_wvIp4TlSDyFujSJLFXR95Q7MfXC_p8M940mL1_GlLdE,
  _02_app_config_no8eyoDhcmPUAB7sUnrIw8RKL_NZ9Fzz9Y_kZ5ZMA50,
  _03_fetch_portal_data_H_YEjZjm4AUPVX8tcTmkXqHNB5y_y2HfwChlPFzMvqQ,
  _04_session_refresh_Dwid7EotMKTrVM8tp0177ZfB4uAt2jbPm29htQ9qLqs,
  custom_scripts_client_pIZ290naDfJUNZUJsKjgqM9ZnAL3xo3XCDBWuMxVvQI,
  customization_D4rIaOwEcdcYiS5vfRBWfZq20PADqfhpb9BYBb7eK64,
  launch_darkly_hHBgUNAMpfD4YdWrMBWNbOq0c7ByxVhdr_dmWlj0Z6w,
  open_fetch_sdks_MitLatvCZQc2WDua8MeksLIAD_kHYOyVbbiIMJb42GI,
  preview_mode_client_Ggr1TCQPc_MSF5nJfnMm_vBIae5Iky0KGDhKudhb5U4,
  unhead_5FH2g9nriaBxpxy5rU5veuYSFDe4pirJ3zF_liE0R8U,
  ssg_detect_0saNPeVRcHLuEtCbvVG5_13hJmbAyslWDGN_Nj3qASM,
  _0_routeRules_vE_1VXroUfmUKH4iu4zzviYcltmxAylDUdjBU2DkTrQ
]