import { parse as parseSetCookie, splitCookiesString } from 'set-cookie-parser'
import { appendResponseHeader } from 'h3'
import { parse as parseCookieHeader, serialize } from 'cookie-es'
import { defu } from 'defu'
import type { FetchContext, FetchOptions, Headers } from 'ofetch'
import type { Cookie } from 'set-cookie-parser'
import type { H3Event } from 'h3'
import type { OpenFetchClientName } from '#build/open-fetch'
import type { NitroFetchRequest, $Fetch } from 'nitropack'

export default defineNuxtPlugin({
  name: 'open-fetch-sdks',
  parallel: true,
  setup() {
    // Only call composables at the root of the plugin setup
    const { openFetch: clients, cookies: { portalAccessToken, portalRefreshToken, portalSessionToken }, portal: { origin: localPortalOrigin } } = useRuntimeConfig().public
    const requestURL = useRequestURL()
    const portalConfigStore = usePortalStore()
    const { context } = storeToRefs(portalConfigStore)
    const sessionStore = useSessionStore()
    const { session } = storeToRefs(sessionStore)
    const requestFetch = useRequestFetch()
    const requestHeaders = useRequestHeaders()
    const cookieRequestHeader = useRequestHeader('cookie')
    const cookieAccess = useCookie(portalAccessToken)
    const cookieRefresh = useCookie(portalRefreshToken)
    const route = useRoute()

    // Create private cookie variables
    let cookiePortalAccessToken: string = ''
    let cookiePortalRefreshToken: string = ''

    /** Automatically retry the request if an error happens and if the response status code is included in this array */
    const defaultRetryStatusCodes = [408, 425, 429, 500, 502, 503, 504]

    const portalApiOptions: FetchOptions = {
      baseURL: '/_proxy', // Always proxy requests to the Portal API
      retryStatusCodes: [
        ...defaultRetryStatusCodes, // Use the default codes
        401, // also include 401 for attempting to refresh the session auth cookie
        404, // also include 404, for attempting to refresh the session auth cookie when a 404 is returned from the Portal API for pages that may be partially/fully public
      ],
      retry: 1, // Number of retry attempts. Do not set to zero, as it will not retry the request
      retryDelay: 100, // Delay between retries in milliseconds
      timeout: 20000, // Timeout after 20 seconds
      credentials: 'include', // Include credentials in the request
      async onRequest({ request, options }: FetchContext) {
        let localRequestCookieHeader: string = ''
        // If the portal is not public and the access cookie is present and the request is to a Portal API endpoint, append the cookie to the request
        const initialCookies = parseCookieHeader(cookieRequestHeader || '')
        for (const initialCookieName in initialCookies) {
          // Update the local cookiePortalAccessToken value if present
          if (initialCookieName === portalAccessToken) {
            cookiePortalAccessToken = initialCookies[initialCookieName] || ''
            continue
          }

          // Update the local cookiePortalRefreshToken value if present
          if (initialCookieName === portalRefreshToken) {
            cookiePortalRefreshToken = initialCookies[initialCookieName] || ''
            continue
          }
        }

        // If Portal authentication is enabled
        if (context.value?.authentication_enabled === true && cookiePortalAccessToken && String(request || '').includes('/api/v')) {
          // Combine the cookies into a single string, and parse them into an object
          const parsedCookies = parseCookieHeader(`${cookieRequestHeader}; ${serialize(portalAccessToken, cookiePortalAccessToken)}`)
          // Loop through the parsed cookies and append them to the local cookie header
          for (const cookie in parsedCookies) {
            // Always strip out the portalSessionToken, and if not hitting the refresh endpoint, strip out the portalRefreshToken since it is only needed when hitting the refresh or logout endpoints
            if (cookie === portalSessionToken || (cookie === portalRefreshToken && !String(request || '').includes('/refresh') && !String(request || '').includes('/logout'))) {
              continue
            }
            localRequestCookieHeader += `${serialize(cookie, parsedCookies[cookie]!)}; `
          }
        } else if (context.value?.authentication_enabled !== true && cookiePortalAccessToken) {
          // Combine the cookies into a single string, and parse them into an object
          const parsedCookies = parseCookieHeader(cookieRequestHeader || '')
          // Loop through the parsed cookies adding them to the request cookies, skipping the auth cookies
          for (const cookie in parsedCookies) {
            // Always strip out the portalSessionToken, and no need for auth cookies if the portal is public
            if (cookie === portalAccessToken || cookie === portalRefreshToken || cookie === portalSessionToken) {
              continue
            }
            localRequestCookieHeader += `${serialize(cookie, parsedCookies[cookie]!)}; `
          }
        }

        // @ts-ignore - Get the Accept header, if it exists
        const acceptHeader = options.headers.accept || options.headers.Accept

        // Ensure headers are bound to request
        options.headers = {
          ...options.headers || {},
          // Always include the original request headers
          ...requestHeaders,
          // Ensure the accept header is set to application/json for GET requests if not already provided
          ...(acceptHeader ? { accept: acceptHeader } : options.method === 'GET' ? { accept: 'application/json' } : {}),
          // !Important: Add custom Portal Client headers for dynamic request routing for all SDK requests
          ...{
            'x-portal-context-origin': requestURL.origin,
            'x-portal-context-hostname': requestURL.hostname,
          },
          // @ts-ignore: Important: include the cookie header in the request
          // !Important: lowercase
          cookie: localRequestCookieHeader,
        } satisfies Headers

        // Create an AbortController instance in case we want to cancel the request
        const controller = new AbortController()
        // If Portal authentication is enabled and the user is not authenticated and there are zero retries left, abort the request (this means that a session refresh has already occurred)
        if (context.value?.authentication_enabled === true && !cookiePortalRefreshToken && !session.value.authenticated && options.retry === 0) {
          // TODO: expose trace id in the UI on the login page? (or in the console)
          options.signal = controller.signal
          controller.abort('Session refresh was attempted, but the user is not authenticated.')
        }
      },
      async onResponseError({ response, options }: FetchContext) {
        if (!options.retry) {
          return
        }

        const isInternalAuthEndpoint = /\/api\/session\/(?:authenticate|refresh|logout)/.test(response?.url || '')
        const isExternalAuthEndpoint = /\/api\/v\d+\/developer\/(?:authenticate|refresh|logout)/.test(response?.url || '')

        // If the response status is 401 and the request is not to an session/authentication endpoint
        if (response?.status === 401 && !isInternalAuthEndpoint && !isExternalAuthEndpoint) {
          // Attempt to refresh the session
          await attemptSessionRefresh()
        } else if (response?.status === 404) {
          // If portal authentication is not enabled, no need to retry or attempt a refresh
          if (context.value?.authentication_enabled !== true) {
            options.retry = 0
            return
          } else if (context.value?.authentication_enabled === true && session.value.authenticated) {
            // If the portal is not public, and the user was _already_ authenticated

            // !IMPORTANT: Always refresh the session for a 404 in this scenario so that
            // we can recover if the user has a valid refresh token but is missing their access token.
            // A 404 with message `Session refresh required` is bound in `layers/core/server/api/[...].ts`.

            // Attempt to refresh the session
            await attemptSessionRefresh()
          }
        }
      },
    }

    /**
     * Attempts to refresh the user's session.
     * If the refresh fails, it clears the cookie values, logs out the user, and redirects them to the login page.
     */
    const attemptSessionRefresh = async (): Promise<void> => {
      try {
        // Use requestFetch so cookies are automatically available
        await requestFetch('/api/session/refresh', {
          method: 'POST',
          headers: {
            // Add custom Portal Client headers for dynamic request routing
            'x-portal-context-origin': requestURL.origin,
            'x-portal-context-hostname': requestURL.hostname,
          },
        })

        // Important: Set the authenticated session value to true (this will also refresh the portal config data)
        session.value.authenticated = true
      } catch {
        // Clear all cookie values since the refresh failed
        cookiePortalAccessToken = ''
        cookiePortalRefreshToken = ''
        cookieAccess.value = null
        cookieRefresh.value = null

        // Store the current route in the cookie to redirect on login
        sessionStore.setLoginReturnPath(route.fullPath)

        await requestFetch('/api/session/logout', {
          method: 'POST',
          headers: {
            // Add custom Portal Client headers for dynamic request routing
            'x-portal-context-origin': requestURL.origin,
            'x-portal-context-hostname': requestURL.hostname,
          },
        })
        // Important: Set the authenticated session value to false (this will also refresh the portal config data)
        session.value.authenticated = false

        /**
         * If the user is not authenticated after refreshing the session, redirect the user
         * to the login page if not navigating to a Custom Page, or paths that could display a custom page.
         *
         * If attempting to navigate to a Custom Page,
         * or any of the `/apis/**` routes
         * or the `/_preview-mode/snippets/[snippet_name]` route
         * allow navigation and the `PageMdcComponent.vue` component
         * will handle the authentication logic based on if the page is public or private.
         *
         * !Important: Any page that can render a Custom MDC Page should be allowed here.
         * !Important: Any page within the `/apis/{api_slug}` route should be allowed here since the pages can be public.
         */
        if (
          !route.path.includes('/login') &&
          route.name !== 'page_slug' &&
          !route.path.startsWith('/apis/') &&
          route.name !== '_preview-mode-snippets-snippet_name'
        ) {
          await navigateTo({
            path: '/login',
            hash: '',
          })
        }
      }
    }

    /**
     * Perform a fetch request with a cookie and updates the cookies based on the response.
     *
     * @param {H3Event} event - The H3Event object.
     * @param {string} url - The URL to fetch.
     * @param options - Additional options for the fetch request.
     * @returns {Promise<Response>} A Promise that resolves to the fetch response.
     */
    const fetchWithCookie = async (event: H3Event, url: string, options: any = {}): Promise<Response> => {
      // Get the response from the server endpoint
      const response = await event.fetch(url, options)
      // Parse the set-cookie header of the response
      const setCookieHeader = response?.headers?.getSetCookie()
      // Parse the set-cookie header into an array of objects since they are all in a single string
      const setcookieHeaderArray: Cookie[] = parseSetCookie(splitCookiesString(setCookieHeader))

      // Loop through the session cookie names and update the cookies according to the `set-cookie` header, if they are present
      for (const cookie of setcookieHeaderArray) {
        // Extract the new properties
        const { name, value, domain, path, expires, maxAge, httpOnly, sameSite, secure } = cookie

        appendResponseHeader(event, 'set-cookie', serialize(name, value, {
          domain,
          path,
          expires,
          maxAge,
          httpOnly,
          // Set SameSite to false on localhost (so that we can also set Secure to false for Safari cookie compatibility)
          sameSite: localPortalOrigin ? false : sameSite as (boolean | 'lax' | 'strict' | 'none' | undefined),
          // Set Secure to false on localhost (for Safari cookie compatibility)
          secure: localPortalOrigin ? false : secure,
        }))

        // Update the local cookiePortalAccessToken value if present
        if (name === portalAccessToken) {
          cookiePortalAccessToken = value
        }

        // Update the local cookiePortalRefreshToken value if present
        if (name === portalRefreshToken) {
          cookiePortalRefreshToken = value
        }
      }

      return response
    }

    return {
      provide: {
        fetchWithCookie,
        // Register SDKs, see https://nuxt-open-fetch.vercel.app/advanced/custom-client
        ...Object.entries(clients).reduce((acc, [name, options]) => {
          return {
            ...acc,
            [name]: createOpenFetch(localOptions => {
              const customClients: OpenFetchClientName[] = ['portalApi']

              let mergedOptions = customClients.includes(name as OpenFetchClientName) ? defu(localOptions, portalApiOptions) : localOptions

              // Add original options
              mergedOptions = defu(mergedOptions, options)

              // Combine the onRequest functions
              mergedOptions.onRequest = async (ctx) => {
                ctx.options.headers = {
                  ...ctx.options.headers || {},
                }
                // Ensure all headers are bound to request
                ctx.options.headers = defu(mergedOptions.headers, ctx.options.headers) as Headers
                if (typeof portalApiOptions?.onRequest === 'function') {
                  await portalApiOptions.onRequest(ctx)
                }
                if (typeof localOptions?.onRequest === 'function') {
                  await localOptions.onRequest(ctx)
                }
              }
              mergedOptions.onRequestError = async (ctx) => {
                if (typeof portalApiOptions?.onRequestError === 'function') {
                  await portalApiOptions.onRequestError(ctx)
                }
                if (typeof localOptions?.onRequestError === 'function') {
                  await localOptions.onRequestError(ctx)
                }
              }
              // Combine the onResponse functions
              mergedOptions.onResponse = async (ctx) => {
                if (typeof portalApiOptions?.onResponse === 'function') {
                  await portalApiOptions.onResponse(ctx)
                }
                if (typeof localOptions?.onResponse === 'function') {
                  await localOptions.onResponse(ctx)
                }
              }
              // !Important: Allows defining local response error options (e.g. ignoring 404 errors)
              mergedOptions.onResponseError = async (ctx) => {
                if (typeof portalApiOptions?.onResponseError === 'function') {
                  await portalApiOptions.onResponseError(ctx)
                }
                if (typeof localOptions?.onResponseError === 'function') {
                  await localOptions.onResponseError(ctx)
                }
              }

              // Return merged options
              return mergedOptions
            }, requestFetch as $Fetch<unknown, NitroFetchRequest>),
          }
        }, {}),
      },
    }
  },
})
