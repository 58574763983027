import type { ComputedRef } from 'vue'
import { computedAsync } from '@vueuse/core'

export default function useCustomStyles(styles: ComputedRef<string>, id?: string) {
  const componentId = id || useId()

  /**
   * We utilize the `computedAsync` function from `@vueuse/core` to ensure the styles are applied after unhead's internal `await nextTick()` has resolved
   * by implementing an artificial delay.
   *
   * !IMPORTANT: This delay should only be applied in the client-side context.
   *
   * This is necessary to ensure the head tag is injected in the Konnect Portal Editor when live-editing the `styles` prop for a component.
   * This should have no impact on the SSR/normal rendering of the parent component.
   * See https://github.com/unjs/unhead/issues/530 for more information.
   */
  const computedStyles = computedAsync(async (): Promise<string | undefined> => {
    if (import.meta.client) {
      // Wait to ensure the styles are available (don't use `nextTick()` here)
      await new Promise((resolve) => setTimeout(resolve, 0))
    }

    if (!styles.value || String(styles.value || '').trim().length === 0) {
      return
    }
    return parseCustomCss(wrapString(styles.value, `#${componentId} {`, '}'))
  },
  // Initial state
  undefined,
  )

  useHead({
    style: [
      () => computedStyles.value ? {
        key: `custom-styles-${componentId}`,
        id: `custom-styles-${componentId}`,
        'data-testid': `custom-styles-${componentId}`,
        innerHTML: computedStyles.value,
        tagPosition: 'head',
        tagPriority: 2000, // Ensure custom styles are applied last, after `portal-custom-css` in `layers/core/app/plugins/customization.ts`
      } : {},
    ],
  })

  return {
    componentId,
  }
}
